import React from 'react';

import warningIcon from '../../assets/icons/warningIcon.svg';
import crossIcon from '../../assets/icons/crossWhite.svg';

interface Props {
  title: string;
  onClick?: () => void;
  closeToast?: () => void;
}

const ErrorMessage: React.FC<Props> = ({ title, onClick, closeToast }) => (
  <div className="error-message-container" onClick={onClick}>
    <div className="title-row">
      <img src={warningIcon} alt="warning" className="warning-icon" />
      <div
        className="cross-icon"
        onClick={(e) => {
          e.preventDefault();
          closeToast?.();
        }}
      >
        <img src={crossIcon} alt="cross" className="cross-icon" />
      </div>
    </div>
    <div className="content-row">
      <div className="content">{title}</div>
    </div>
  </div>
);

export default ErrorMessage;
