import React from 'react';

import BasicDataPage from './pages/BasicDataPage';
import BlackoutBoxPage from './pages/BlackoutBoxPage';
import HotWaterPumpPage from './pages/HotWaterPumpPage';
import MemoryExtensionPage from './pages/MemoryExtensionPage';
import StorageExtensionPage from './pages/StorageExtensionPage';
import PhotovoltaikPage from './pages/PhotovoltaikPage';
import WallboxPage from './pages/WallboxPage';
import pageUrl from './const/pages';
import FinancingPage from './pages/FinancingPage';

export const routes: { path: string; element: React.ReactElement }[] = [
  {
    path: pageUrl.basicData,
    element: <BasicDataPage />,
  },
  {
    path: pageUrl.photovoltaikQuote,
    element: <PhotovoltaikPage />,
  },
  {
    path: pageUrl.wallboxQoute,
    element: <WallboxPage />,
  },
  {
    path: pageUrl.blackoutBox,
    element: <BlackoutBoxPage />,
  },
  {
    path: pageUrl.hotWaterPump,
    element: <HotWaterPumpPage />,
  },
  {
    path: pageUrl.memoryExtension,
    element: <MemoryExtensionPage />,
  },
  {
    path: pageUrl.storageExtension,
    element: <StorageExtensionPage />,
  },
  {
    path: pageUrl.financing,
    element: <FinancingPage />,
  },
];
