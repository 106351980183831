/**
 * Created by Max Gornostayev 3/17/23
 *
 * Date help library - functions for working with dates
 */

import moment from 'moment';

import config from '../config';

const DateUtils = {
  /*
   * get timestamp value
   */
  timestamp: (isMilliseconds?: boolean): number => parseInt(String(isMilliseconds ? moment().valueOf() : moment().unix()), 10),

  /*
   * get difference(in years) from current date and 'dateString' variable
   */
  getDiffYearFromNow: (dateString: string, fromformat: string = config.dateFormat.serverDate): number => {
    const now = moment();
    const date = moment(dateString, fromformat);

    return now.diff(date, 'years', true);
  },

  /*
   * get difference(in days) from startDateString and dateString
   */
  getDiffDaysFromDate: (dateString: string, startDateString: string, fromformat: string = config.dateFormat.serverDate): number => {
    const d = moment(startDateString, fromformat);
    const date = moment(dateString, fromformat);

    return d.diff(date, 'days', true);
  },

  /*
   * convert date js object into string with format
   */
  convertFromDate: (date: string, format?: string): string => {
    const d = moment(date || null);

    return d.format(format || config.dateFormat.date);
  },

  /*
   * get difference(in years) from current date and 'dateString' variable
   */
  isDateValid: (dateString: string, fromformat?: string): boolean => {
    const date = moment(dateString, fromformat || config.dateFormat.date);

    return date.isValid();
  },

  /*
   * convert date from 'fromformat' to 'toformat'
   */
  getFormatServerDate: (d: string, toformat?: string, fromformat?: string): string =>
    d
      ? moment(d, fromformat || config.dateFormat.date).format(toformat || config.dateFormat.serverDate)
      : moment().format(toformat || config.dateFormat.serverDate),

  /*
   * get format date from 'fromformat' to 'toformat'
   */
  getFormatDate: (d: string, toformat?: string, fromformat?: string): string =>
    d
      ? moment(d, fromformat || config.dateFormat.serverDate).format(toformat || config.dateFormat.date)
      : moment().format(toformat || config.dateFormat.date),

  /*
   * get year
   * @param d - string
   * @param fromformat - string, from what format
   */
  getYear: (d: string, fromformat?: string) => {
    const dateObj = d ? moment(d, fromformat || config.dateFormat.serverDate) : moment();

    return dateObj.year();
  },
};

export default DateUtils;
