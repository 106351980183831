/**
 * Created by Max Gornostayev 04/28/22
 *
 * This is a router for routing logic in security pages
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useStore from '../hooks/useStore';
// import Loading from '../components/elements/Loading';
import pageUrl from '../const/pages';

const RouteAuth = observer(() => {
  const { userStore } = useStore();
  const location = useLocation();

  // check if is authorized
  if (!userStore.isAuthorized) {
    return <Navigate to={pageUrl.page404} />;
  }

  const isCustomerPages = location.pathname.includes(pageUrl.missingRequiredData) || location.pathname.includes(pageUrl.basicData);

  if (!userStore.isCustomerFilled && !isCustomerPages) {
    return <Navigate to={pageUrl.missingRequiredData} />;
  }

  return <Outlet />;
});

export default RouteAuth;
