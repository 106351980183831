/**
 * Created by Max Gornostayev 01/14/24
 *
 * this is basic data page for the customer
 */

import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';

import Button from '../components/buttons/Button';
import Bottom from '../components/theme/Bottom';
import InputText, { InputRefType } from '../components/forms/InputText';
import Validation from '../lib/Validation';
import { ICustomer } from '../interfaces/IModels';
import i18n from '../i18n';
import pageUrl from '../const/pages';
import useStore from '../hooks/useStore';
import ContentArea from '../components/theme/ContentArea';
import Slider from '../components/forms/Slider';
import Checkbox from '../components/forms/Checkbox';
import AddressInput from '../components/forms/AddressInput';
import { GooglePlaceDetails } from '../services/GoogleMapsAutocomplete';
import Tooltip from '../components/elements/Tooltip';
import { ProfitabilityCalculationForm } from '../interfaces/ICache';
import '../styles/secure.scss';
import { useBSHNavigation } from '../hooks/useBSHNavigation';
import { CrmAddress } from '../interfaces/IN8N';
import Select from '../components/forms/Select';
import { SelectOption } from '../interfaces/IComponents';
import { salutationFormOptions } from '../config';

const BasicDataPage = observer(() => {
  // get user store
  const { userStore, dataStore, photovoltaikStore } = useStore();
  const { customer } = userStore;
  const { formSwitchers: switchers, internalObjectAddress } = dataStore;
  const { profitabilityCalculationForm: profitForm, electricityPrice } = photovoltaikStore;
  const { priceInflation, electricityConsumption, powerConsumption, grossElectricityCosts, secondElectricityMeter, forecastPeriod } =
    profitForm;
  const { goToNextPage, goToPreviousPage } = useBSHNavigation();

  const isProfitabilityVisible = switchers.find(({ checked, url }) => checked && url === pageUrl.photovoltaikQuote);

  // create refs
  const firstNameRef = useRef<InputRefType>(null);
  const lastNameRef = useRef<InputRefType>(null);
  const postcodeRef = useRef<InputRefType>(null);
  const cityRef = useRef<InputRefType>(null);
  const emailRef = useRef<InputRefType>(null);
  const electricityConsumptionRef = useRef<InputRefType>(null);
  const powerConsumptionRef = useRef<InputRefType>(null);
  const grossElectricityCostsRef = useRef<InputRefType>(null);

  const [streetValidationMsg, setStreetValidationMsg] = useState('');
  const [isAnotherAddress, setIsAnotherAddress] = useState(false);

  // forecast period options
  const forecastPeriodOptions = [
    { id: 25, value: `25 ${i18n.t('common.years')}`, checked: forecastPeriod === 25 },
    { id: 30, value: `30 ${i18n.t('common.years')}`, checked: forecastPeriod === 30 },
  ];
  const [nextButtonTooltip, setNextButtonTooltip] = useState(false);

  // set user data to store
  const setUserData = (newCustomer: ICustomer) => {
    userStore.updateCustomer(newCustomer);
  };

  const setAddress = (address: CrmAddress) => {
    dataStore.setInternalObjectAddress(address);
  };

  // set profitability section data to store
  const setProfitForm = (newForm: ProfitabilityCalculationForm) => {
    photovoltaikStore.setProfitabilityCalculationForm(newForm);
  };

  const highlightRequiredFields = () => {
    let formValidated = true;

    const basicRefs: [InputRefType | null, string?][] = [
      [firstNameRef.current, customer.firstName?.toString()],
      [lastNameRef.current, customer.lastName?.toString()],
      [cityRef.current, internalObjectAddress?.city?.toString()],
      [postcodeRef.current, internalObjectAddress?.postcode?.toString()],
      [emailRef.current, customer.email],
    ];

    const phovoltaikRefs: [InputRefType | null, string?][] = [
      [electricityConsumptionRef.current, electricityConsumption?.toString()],
      [powerConsumptionRef.current, powerConsumption?.toString()],
      [grossElectricityCostsRef.current, grossElectricityCosts?.toString()],
    ];

    basicRefs.map(([refValue, text]) => {
      if (refValue) {
        const validObj = refValue.inputValidate(text ?? '');

        if (!validObj.validated) {
          refValue.setValidation(validObj);
          formValidated = false;
        }
      }

      return refValue;
    });

    const streetValidation = Validation.addressWithoutNumber(internalObjectAddress?.street ?? '');

    if (!streetValidationMsg && !streetValidation.validated) {
      setStreetValidationMsg(streetValidation.msg);
      formValidated = false;
    }

    if (isProfitabilityVisible) {
      phovoltaikRefs.map(([refValue, text]) => {
        if (refValue) {
          const validObj = refValue.inputValidate(text ?? '');

          if (!validObj.validated) {
            refValue.setValidation(validObj);
            formValidated = false;
          }
        }

        return refValue;
      });
    }

    return formValidated;
  };

  useEffect(() => {
    highlightRequiredFields();
  }, [customer, internalObjectAddress, profitForm, isProfitabilityVisible]);

  // button handler
  const next = async () => {
    const formValidated = highlightRequiredFields();

    if (formValidated) {
      dataStore.syncBasicParseData();
      goToNextPage();
    }
  };

  // handle click on google address sugestion
  const handleStreetSelection = (details: GooglePlaceDetails) => {
    const refs: [InputRefType | null, string][] = [
      [postcodeRef.current, details.postalCode],
      [cityRef.current, details.city],
    ];

    refs.map(([refValue, text]) => {
      if (refValue) {
        const validObj = refValue.inputValidate(text);

        refValue.setValidation(validObj);
      }

      return refValue;
    });

    dataStore.setInternalObjectAddress({
      ...internalObjectAddress,
      postcode: details.postalCode,
      city: details.city,
      street: `${details.streetName} ${details.streetNumber}`,
    });

    const streetValidation = Validation.addressWithoutNumber(details.streetName);

    setStreetValidationMsg(streetValidation.validated ? '' : streetValidation.msg);
  };

  const emptyValidateFunction = (p: string) => Validation.emptyValue(p, i18n.t('validations.fieldRequired'));

  const isFormValid = () => {
    let formValid = true;

    if (!customer.firstName || !customer.lastName || !internalObjectAddress?.city || !internalObjectAddress?.street) {
      formValid = false;
    }

    const isEmailValid = Validation.email(customer.email ?? '').validated;
    const isPostcodeValid = Validation.postcode(internalObjectAddress?.postcode?.toString() ?? '').validated;

    if (!isEmailValid || !isPostcodeValid) {
      formValid = false;
    }

    if (isProfitabilityVisible) {
      if (!electricityConsumption || !powerConsumption || !grossElectricityCosts) {
        formValid = false;
      }
    }

    return formValid;
  };

  const salutationSelectValue = salutationFormOptions.find((option) => option.id === customer.salutationForm);

  // handle select value change
  const handleSelectChange = (option: SelectOption) => {
    const newValue = option.id === '' ? null : option.id.toString();

    setUserData({
      ...customer,
      salutationForm: newValue ?? undefined,
    });
  };

  // main rendering
  return (
    <ContentArea>
      <div className="content-main">
        <h2 className="title">{i18n.t('basicDataPage.contactDetails').toUpperCase()}</h2>
        <div className="container">
          <div className="row">
            <Select
              options={salutationFormOptions}
              value={salutationSelectValue}
              title={i18n.t('missingRequiredDataPage.salutation')}
              onSuggestionSelected={handleSelectChange}
              testId="salutation-form"
            />
          </div>
          <div className="row">
            <InputText
              role="firstNameInput"
              title={i18n.t('missingRequiredDataPage.firstName')}
              value={customer.firstName}
              onChange={(val) => setUserData({ ...customer, firstName: val as string })}
              testId="first-name-input"
              className="basic-data-input"
              validateFunc={emptyValidateFunction}
            />
            <InputText
              role="lastNameInput"
              title={i18n.t('missingRequiredDataPage.lastName')}
              value={customer.lastName}
              onChange={(val) => setUserData({ ...customer, lastName: val as string })}
              testId="last-name-input"
              className="basic-data-input"
              validateFunc={emptyValidateFunction}
            />
          </div>
          <div className="row">
            <InputText
              ref={emailRef}
              role="emailInput"
              title={i18n.t('missingRequiredDataPage.email')}
              value={customer.email}
              onChange={(val) => setUserData({ ...customer, email: val as string })}
              validateFunc={(val) => Validation.email(val)}
              testId="email-input"
            />
          </div>
        </div>
        <div className="title-row">
          <div className="title">{i18n.t('basicDataPage.propertyAddress').toUpperCase()}</div>
          <Checkbox
            options={[{ value: 'abweichend?', checked: isAnotherAddress, testId: 'another-address-checkbox' }]}
            onChange={(options) => setIsAnotherAddress(options[0].checked)}
          />
        </div>
        <div className="container">
          <div className="row">
            <AddressInput
              role="street"
              title={i18n.t('missingRequiredDataPage.streetName')}
              value={internalObjectAddress?.street ?? ''}
              onChange={(val) => {
                const validObj = Validation.addressWithoutNumber(val?.toString() ?? '');

                setAddress({ ...internalObjectAddress, street: val as string });
                setStreetValidationMsg(validObj.msg);
              }}
              className="autocomplete-input"
              onStreetSelected={handleStreetSelection}
              testId="street-name-input"
              validateMsg={streetValidationMsg}
              validateFunc={(val) => Validation.addressWithoutNumber(val)}
              isDisabled={!isAnotherAddress}
            />
          </div>
          <div className="row">
            <InputText
              ref={postcodeRef}
              role="postcodeInput"
              title={i18n.t('missingRequiredDataPage.postcode')}
              value={internalObjectAddress?.postcode ?? ''}
              onChange={(val) => setAddress({ ...internalObjectAddress, postcode: val as string })}
              validateFunc={(val) => Validation.postcode(val)}
              testId="postcode-input"
              className="basic-data-input"
              isDisabled={!isAnotherAddress}
            />
            <InputText
              ref={cityRef}
              role="cityInput"
              title={i18n.t('missingRequiredDataPage.city')}
              value={internalObjectAddress?.city ?? ''}
              onChange={(val) => setAddress({ ...internalObjectAddress, city: val as string })}
              testId="city-input"
              className="basic-data-input"
              validateFunc={emptyValidateFunction}
              isDisabled={!isAnotherAddress}
            />
          </div>
        </div>
        {isProfitabilityVisible && (
          <>
            <h2 className="title">{i18n.t('basicDataPage.profitabilityCalculation').toUpperCase()}</h2>
            <div className="container">
              <div className="price-inflation-row">
                <InputText
                  ref={electricityConsumptionRef}
                  title={i18n.t('basicDataPage.electricityConsumption')}
                  value={electricityConsumption}
                  type="number"
                  step={0.01}
                  className="price-slider"
                  onChange={(val) => {
                    const newValue = val ? Number(Number(val).toFixed(2)) : undefined;

                    setProfitForm({
                      ...profitForm,
                      electricityConsumption: newValue,
                    });
                  }}
                  min={0}
                  suffix="kWh"
                  testId="electricity-input"
                  validateFunc={(p) => {
                    const num = Number(p);

                    if (num === 0) {
                      return {
                        validated: false,
                        msg: i18n.t('validations.fieldRequired'),
                      };
                    }

                    return { validated: true, msg: '' };
                  }}
                />
                <Checkbox
                  title={i18n.t('basicDataPage.secondElectricity')}
                  options={[{ value: i18n.t('common.yes'), checked: secondElectricityMeter, testId: 'second-electricity-checkbox' }]}
                  className="checkboxes"
                  onChange={(options) => {
                    setProfitForm({
                      ...profitForm,
                      secondElectricityMeter: options[0].checked,
                    });
                  }}
                  hint={i18n.t('basicDataPage.secondElectricityHint')}
                  isSingle
                />
              </div>
              <div className="price-inflation-row">
                <Slider
                  value={priceInflation}
                  min={3}
                  max={8}
                  step={0.5}
                  testId="inflation-slider"
                  label={i18n.t('basicDataPage.priceIncrease')}
                  onChange={(val) => setProfitForm({ ...profitForm, priceInflation: val })}
                  inputSuffix="%"
                  className="price-slider"
                />
                <Checkbox
                  title={i18n.t('basicDataPage.forecastPeriod')}
                  options={forecastPeriodOptions}
                  className="checkboxes"
                  onChange={(options) => {
                    setProfitForm({
                      ...profitForm,
                      forecastPeriod: options[0]?.checked ? 25 : 30,
                    });
                  }}
                  isSingle
                />
              </div>
              <h2 className="lastRowTitle">ERMITTLUNG STROMPREIS PRO KWH</h2>
              <div className="row">
                <InputText
                  ref={powerConsumptionRef}
                  type="number"
                  title={i18n.t('basicDataPage.powerConsumption')}
                  value={powerConsumption}
                  onChange={(value) => {
                    const newValue = value ? Number(Number(value).toFixed(2)) : undefined;

                    setProfitForm({
                      ...profitForm,
                      powerConsumption: newValue,
                    });
                  }}
                  testId="power-input"
                  min={0}
                  suffix="kWh"
                  hint={i18n.t('basicDataPage.consumptionKWh')}
                  className="consumption-input"
                  validateFunc={(p) => {
                    const num = Number(p);

                    if (num === 0) {
                      return {
                        validated: false,
                        msg: i18n.t('validations.fieldRequired'),
                      };
                    }

                    return { validated: true, msg: '' };
                  }}
                />
                <InputText
                  ref={grossElectricityCostsRef}
                  title={i18n.t('basicDataPage.grossElectricityCosts')}
                  value={grossElectricityCosts}
                  onChange={(value) => {
                    const newValue = value ? Number(Number(value).toFixed(2)) : undefined;

                    setProfitForm({
                      ...profitForm,
                      grossElectricityCosts: newValue,
                    });
                  }}
                  testId="gross-input"
                  min={0}
                  type="number"
                  step={0.01}
                  suffix="€"
                  hint={i18n.t('basicDataPage.invoiceYear')}
                  className="consumption-input"
                  validateFunc={(p) => {
                    const num = Number(p);

                    if (num === 0) {
                      return {
                        validated: false,
                        msg: i18n.t('validations.fieldRequired'),
                      };
                    }

                    return { validated: true, msg: '' };
                  }}
                />
                <InputText
                  title={i18n.t('basicDataPage.electricityPrice')}
                  value={electricityPrice}
                  type="number"
                  isDisabled
                  testId="electricity-price-input"
                  min={0}
                  suffix="Cent"
                  className="consumption-input"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Bottom>
        <Button role="btnCancel" text={i18n.t('buttons.back')} onClick={goToPreviousPage} testId="cancel-button" />
        <Tooltip isShow={nextButtonTooltip && !isFormValid()} text={i18n.t('validations.validateNextButton')} position="top" bottomButtons>
          <Button
            role="btnNext"
            text={i18n.t('buttons.next')}
            onClick={next}
            leftMargin20
            isDisabled={!isFormValid()}
            isGreen
            onMouseEnter={() => setNextButtonTooltip(true)}
            onMouseLeave={() => setNextButtonTooltip(false)}
            testId="next-button"
          />
        </Tooltip>
      </Bottom>
    </ContentArea>
  );
});

export default BasicDataPage;
