/**
 * Created by Ilya Savinov 19/01/24
 *
 * this is a Google maps api class
 *
 * props:
 * @key - string - google api key
 */
import { Loader } from '@googlemaps/js-api-loader';

export interface GooglePlaceDetails {
  streetName: string;
  streetNumber: string;
  city: string;
  postalCode: string;
}

class GoogleMapsAutocomplete {
  private loader: Loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
    version: 'weekly',
  });

  private autocompleteOptions = {
    componentRestrictions: { country: 'de' },
    fields: ['address_components'],
    types: ['route', 'street_number', 'locality', 'postal_code'],
  };

  // get address suggestions based on input
  async getAutocompleteService(input: HTMLInputElement): Promise<google.maps.places.Autocomplete> {
    const { Autocomplete } = (await this.loader.importLibrary('places')) as google.maps.PlacesLibrary;
    const service = new Autocomplete(input, this.autocompleteOptions);

    return service;
  }

  extractAddressComponents(addressComponents: google.maps.places.AddressComponent[]): GooglePlaceDetails {
    const streetNameType = 'route';
    const streetNumberType = 'street_number';
    const cityType = 'locality';
    const postalCodeType = 'postal_code';

    const data = addressComponents.reduce(
      (acc, component: any) => {
        const componentTypes = component.types as string[];

        if (componentTypes.includes(streetNameType)) {
          acc.streetName = component.long_name;
        }

        if (componentTypes.includes(streetNumberType)) {
          acc.streetNumber = component.long_name;
        }

        if (componentTypes.includes(cityType)) {
          acc.city = component.long_name;
        }

        if (componentTypes.includes(postalCodeType)) {
          acc.postalCode = component.long_name;
        }

        return acc;
      },
      {
        streetName: '',
        streetNumber: '',
        city: '',
        postalCode: '',
      },
    );

    return data;
  }
}

export default new GoogleMapsAutocomplete();
