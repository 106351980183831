/* eslint-disable max-len */
/**
 * Created by Max Gornostayev 01/15/24
 *
 * this is a left panel
 */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductResultType } from 'bsh-calculator';

import Button from '../buttons/Button';
import i18n from '../../i18n';
import ToggleSwitch from '../elements/ToggleSwitch';
import useStore from '../../hooks/useStore';
import Tooltip from '../elements/Tooltip';
import pages from '../../const/pages';
import createOffer from '../../assets/icons/menu/createOffer.svg';
import history from '../../assets/icons/menu/history.svg';
import dataSheets from '../../assets/icons/menu/dataSheets.svg';
import energyConcept from '../../assets/icons/menu/energyConcept.svg';
import withoutWi from '../../assets/icons/menu/withoutWi.svg';
import menuIcon from '../../assets/icons/menu/menuIcon.svg';
import ProgressIndicator from '../elements/ProgressIndicator';
import { useBSHNavigation } from '../../hooks/useBSHNavigation';
import { calculateFinancingInterestRate } from '../../helpers/finance';
import { useCalculator } from '../../hooks/useCalculator';
import { toFloatNumber } from '../../lib/PageUtils';

const Left = observer(() => {
  const { dataStore, userStore, cacheStore, memoryExtensionStore, financingStore, photovoltaikStore } = useStore();
  const { formSwitchers, isMenuExpended, isGenerateQuoteEnable, isPdfLoading, pdfQuote, isGenerateQuoteValidated } = dataStore;
  const [tooltip, setTooltip] = useState({ visible: false, url: '', text: '' });
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [cacheLoader, setCacheLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { goToPreviousPage } = useBSHNavigation();
  const { calculatorResult, calculator } = useCalculator();
  const { pvProducts = {} } = calculatorResult as ProductResultType;

  const summmaryPriceNetWithSolarSpecial = Number(pvProducts.summaryPriceNet);

  const summaryPriceNetWitSolarSpecialWithoutDiscount =
    Number(summmaryPriceNetWithSolarSpecial) + Number(financingStore.financingForm.discountInEuros || 0);

  const calculateDiscountInPercent = () =>
    calculator.calculateDiscountInPercent({
      discountInEuros: Number(financingStore.financingForm.discountInEuros) as number,
      summaryPriceNetWithSolarSpecial: summaryPriceNetWitSolarSpecialWithoutDiscount,
    });

  const calculateDiscountInEuro = (percent: number) =>
    calculator.calculateDiscountInEuros({
      discountInPercent: percent,
      summaryPriceNetWithSolarSpecial: summaryPriceNetWitSolarSpecialWithoutDiscount,
    });

  const onDiscountInPercentBlur = (percent?: number) => {
    let adjustedPercent = typeof percent === 'number' ? percent : (financingStore.financingForm.discountInPercent as number);

    if (Number(adjustedPercent) <= 0) {
      adjustedPercent = 0;
    } else if (Number(adjustedPercent) >= 100) {
      adjustedPercent = 100;
    }

    const res = calculateDiscountInEuro(adjustedPercent);

    financingStore.setFinancingForm({
      ...financingStore.financingForm,
      discountInEuros: toFloatNumber(res, 2),
      discountInPercent: adjustedPercent,
    });
  };

  const onDiscountInEurosBlur = () => {
    if (!Number.isNaN(Number(financingStore.financingForm.discountInEuros))) {
      const res = calculateDiscountInPercent();

      if (res >= 100) {
        financingStore.setFinancingForm({
          ...financingStore.financingForm,
          discountInPercent: 100,
        });

        onDiscountInPercentBlur(100);

        return;
      }

      if (res <= 0) {
        financingStore.setFinancingForm({
          ...financingStore.financingForm,
          discountInPercent: 0,
        });

        onDiscountInPercentBlur(0);

        return;
      }

      financingStore.setFinancingForm({
        ...financingStore.financingForm,
        discountInPercent: res,
        discountInEuros: financingStore.financingForm.discountInEuros,
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPdfLoading || cacheLoader) {
        const newValue = loadingProgress + 1;

        setLoadingProgress(newValue >= 99 ? 99 : newValue);
      } else {
        setLoadingProgress(0);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [isPdfLoading, loadingProgress, cacheLoader]);

  /**
   * handle click on forms toggles
   * @param formUrl - string - form url
   */
  const handleToggleClick = (formUrl: keyof typeof pages) => () => {
    const changedSwitchers = formSwitchers.map((form) => {
      if (form.url === formUrl) {
        const isDisableAction = form.checked;

        const isSingleActive =
          formSwitchers.reduce((acc, switcher) => {
            if (switcher.checked && switcher.url !== pages.basicData && switcher.url !== pages.financing) {
              return acc + 1;
            }

            return acc;
          }, 0) === 1;

        const disabled = isDisableAction && isSingleActive;

        if (disabled) {
          setTooltip({ visible: true, url: form.labelTranslation, text: i18n.t('leftArea.minOneToggle') });
        }

        const newForm = {
          ...form,
          checked: disabled ? true : !form.checked,
          disabled,
        };

        if (form.url === pages.storageExtension && !newForm.checked) {
          dataStore.setStoragesExtensionForm({ storageExtensionSelectionId: null });
        }

        if (form.url === pages.memoryExtension && !newForm.checked) {
          memoryExtensionStore.setMemoryExtensionForm({
            memoryExtensionSelectionId: null,
            numberAdditionalBattery: 0,
            numberExistingBatteries: 0,
          });
        }

        // make switcher disabled if only one is activate
        return newForm;
      }

      return form;
    });

    const isPhotovoltaikChecked = changedSwitchers.find((form) => form.url === pages.photovoltaikQuote)?.checked;

    if (formUrl === pages.photovoltaikQuote && isPhotovoltaikChecked) {
      const { storagesId } = photovoltaikStore;

      dataStore.setProductsBasedOnStorage(storagesId);
    }

    const isSingleActive =
      changedSwitchers.reduce((acc, switcher) => {
        if (switcher.checked && switcher.url !== pages.basicData && switcher.url !== pages.financing) {
          return acc + 1;
        }

        return acc;
      }, 0) === 1;

    // make all switchers enabled if not only single active
    const newSwitchers = changedSwitchers.map((form) => ({
      ...form,
      disabled: !isSingleActive ? false : form.disabled,
    }));

    if (!isSingleActive) {
      setTooltip({ visible: false, url: '', text: '' });
    }

    const currentPageSwitcherIndex = newSwitchers.findIndex((form) => location.pathname.includes(form.url));
    const currentPageSwitcher = newSwitchers[currentPageSwitcherIndex];

    // if current page checkbox was unchecked

    if (currentPageSwitcher && !currentPageSwitcher.checked && currentPageSwitcher.url === formUrl) {
      const previousSwitchers = newSwitchers.filter((form, index) => form.checked && index < currentPageSwitcherIndex);
      const previousSwitcher = previousSwitchers.pop();

      if (previousSwitcher) {
        navigate(previousSwitcher.url);
      }
    }

    // Docs 10.6.1 - 10.6.4
    const isPhovoltaik = newSwitchers.find((form) => form.url === pages.photovoltaikQuote && form.checked);
    const isHotWaterPump = newSwitchers.find((form) => form.url === pages.hotWaterPump && form.checked);
    const resultSwitchers = newSwitchers.map((form) => {
      if (form.url === pages.financing) {
        if (isPhovoltaik || isHotWaterPump) {
          return {
            ...form,
            checked: true,
          };
        }

        if (!isPhovoltaik || !isHotWaterPump) {
          // navigate to previous page if no pv or wp forms is active
          goToPreviousPage();

          return {
            ...form,
            checked: false,
          };
        }

        return form;
      }

      return form;
    });

    // Financing rate changes
    const isWpSelected = resultSwitchers.find((feature) => feature.url === pages.hotWaterPump && feature.checked)?.checked;

    financingStore.setFinancingForm({
      ...financingStore.financingForm,
      financingInterestRate: calculateFinancingInterestRate(isWpSelected),
    });

    dataStore.setFormSwitchers(resultSwitchers);
  };

  /**
   * open pdfTemplateEnergyMapPhotovoltaic pdf
   */
  const openEnergyMapPhotovoltaik = async () => {
    if (pdfQuote?.pdfTemplateEnergyMapPhotovoltaic) {
      window.open(
        `${process.env.REACT_APP_GATEWAY_URL}/file/quote-pdf/${pdfQuote.objectId}/pdfTemplateEnergyMapPhotovoltaic?token=${userStore.authToken}`,
        '_blank',
      );

      return;
    }

    if (pdfQuote?.pdfTemplateEnergyMapWithoutPhotovoltaic) {
      window.open(
        `${process.env.REACT_APP_GATEWAY_URL}/file/quote-pdf/${pdfQuote.objectId}/pdfTemplateEnergyMapWithoutPhotovoltaic?token=${userStore.authToken}`,
        '_blank',
      );
    }
  };

  /**
   * open energyMapPhotovoltaicWithoutWi pdf
   */
  const openTemplateEnergyMapPhotovoltaicWithoutWi = () => {
    if (pdfQuote?.pdfTemplateEnergyMapPhotovoltaicWithoutWi) {
      window.open(
        `${process.env.REACT_APP_GATEWAY_URL}/file/quote-pdf/${pdfQuote.objectId}/pdfTemplateEnergyMapPhotovoltaicWithoutWi?token=${userStore.authToken}`,
        '_blank',
      );
    }
  };

  /**
   * open pdfTemplateDatenblaetter pdf
   */
  const openProductMap = () => {
    if (pdfQuote?.pdfTemplateDatenblaetter) {
      window.open(
        `${process.env.REACT_APP_GATEWAY_URL}/file/quote-pdf/${pdfQuote.objectId}/pdfTemplateDatenblaetter?token=${userStore.authToken}`,
        '_blank',
      );
    }
  };

  const showedForms = formSwitchers.filter((form) => form.url !== pages.basicData && form.url !== pages.financing);

  return (
    <div className={`left-area ${isMenuExpended ? '' : 'collapsed'}`} role="leftArea">
      <div className="buttons-container">
        <Button
          onClick={() => dataStore.setMenuExpended(!isMenuExpended)}
          collapsed={!isMenuExpended}
          text="Menu"
          isFullWidth
          className="menu-button"
          icon={menuIcon}
          showIcon
        />
        {(isPdfLoading || cacheLoader) && (
          <ProgressIndicator
            label={cacheLoader ? i18n.t('forms.productsReloading') : i18n.t('leftArea.offerCreated')}
            percentage={loadingProgress}
          />
        )}
        {!(isPdfLoading || cacheLoader) && (
          <Button
            text={i18n.t('buttons.createNewOffer')}
            isFullWidth
            icon={createOffer}
            collapsed={!isMenuExpended}
            isDisabled={!isGenerateQuoteEnable || !isGenerateQuoteValidated}
            isGreen
            onClick={() => dataStore.createQuote(location.pathname, onDiscountInEurosBlur)}
          />
        )}
        <Button text={i18n.t('buttons.history')} isFullWidth isTransparent icon={history} collapsed={!isMenuExpended} isDisabled />
        <Button
          text={i18n.t('buttons.openDataSheets')}
          isFullWidth
          isTransparent
          icon={dataSheets}
          collapsed={!isMenuExpended}
          isDisabled={!pdfQuote?.pdfTemplateDatenblaetter}
          onClick={openProductMap}
        />
        <Button
          text={i18n.t('buttons.openEnergyConcept')}
          isFullWidth
          isTransparent
          icon={energyConcept}
          collapsed={!isMenuExpended}
          isDisabled={!pdfQuote?.pdfTemplateEnergyMapPhotovoltaic && !pdfQuote?.pdfTemplateEnergyMapWithoutPhotovoltaic}
          onClick={openEnergyMapPhotovoltaik}
        />
        <Button
          text={i18n.t('buttons.offerWithoutWi')}
          isFullWidth
          isTransparent
          icon={withoutWi}
          collapsed={!isMenuExpended}
          isDisabled={!pdfQuote?.pdfTemplateEnergyMapPhotovoltaicWithoutWi}
          onClick={openTemplateEnergyMapPhotovoltaicWithoutWi}
        />
        {userStore.user.getPermissions().productsResetPermission && (
          <Button
            text={i18n.t('buttons.reloadProducts')}
            isFullWidth
            isTransparent
            collapsed={!isMenuExpended}
            isDisabled={cacheLoader}
            onClick={async () => {
              setCacheLoader(true);
              await cacheStore.resetCache();
              setCacheLoader(false);
            }}
          />
        )}
      </div>
      <div className="toggles-container" data-testid="toggles-container">
        {showedForms.map(({ url, checked, disabled, labelTranslation }) => (
          <Tooltip
            key={url}
            text={tooltip.text}
            isShow={tooltip.url === url && tooltip.visible}
            isFullWidth
            position="top"
            tooltipWidth={150}
          >
            <ToggleSwitch
              label={i18n.t(labelTranslation).toUpperCase()}
              checked={checked}
              isDisabled={disabled}
              onClick={handleToggleClick(url as keyof typeof pages)}
              onMouseEnter={() =>
                tooltip.url === url &&
                setTooltip({
                  ...tooltip,
                  visible: true,
                })}
              onMouseLeave={() =>
                setTooltip({
                  ...tooltip,
                  visible: false,
                })}
              collapsed={!isMenuExpended}
            />
          </Tooltip>
        ))}
      </div>
    </div>
  );
});

export default Left;
