/**
 * Created by Ilya Savinov 19/01/24
 *
 * this is a Tooltip component
 *
 * props:
 * @children - ReactNode - for children component
 * @text - string - title
 * @isVisible - bool - is tooltip visible
 * @position - 'left' | 'right' | 'top' | 'bottom' - position of the tooltip depends on children
 * @isFullWidth - bool - is button use full width of the parent
 * @className - string - custom class name
 */
import React, { ReactNode } from 'react';

import { IFormElementProps } from '../../interfaces/IComponents';

export type TooltipPosition = 'left' | 'right' | 'top' | 'bottom';

interface TooltipProps extends IFormElementProps {
  children: ReactNode;
  text: string;
  position?: TooltipPosition;
  bottomButtons?: boolean;
  tooltipWidth?: number;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  text,
  isShow,
  position = 'top',
  isFullWidth = false,
  bottomButtons = false,
  className = '',
  tooltipWidth,
}) => (
  // eslint-disable-next-line max-len
  <div
    // eslint-disable-next-line max-len
    className={`tooltip-container ${isShow ? 'visible' : ''} position-${position} ${isFullWidth ? 'full-width' : ''} ${bottomButtons ? 'bottom-buttons' : ''} ${className}`}
  >
    {children}
    <div className="tooltip-box" style={{ width: tooltipWidth ? `${tooltipWidth}px` : 'max-content' }}>
      {text}
    </div>
  </div>
);

export default Tooltip;
