/**
 * Created by Vladyslav Dubyna 01/29/24
 *
 * this is a custom reusable dropdown component
 */

import React, { FC, ReactElement, useRef, useState, useEffect } from 'react';

import ArrowIcon from '../../assets/icons/arrow.svg';

type Dropdown = {
  summaryTitle: string;
  icon?: string;
  children?: ReactElement | string;
  defaultOpen?: boolean;
  wrapperClass?: string;
  customArrow?: string;
  summaryTestId?: string;
  contentTestId?: string;
};

const Dropdown: FC<Dropdown> = ({
  summaryTitle,
  icon = ArrowIcon,
  children,
  defaultOpen = false,
  wrapperClass = '',
  customArrow,
  summaryTestId = 'dropdown-summary',
  contentTestId = 'dropdown-content',
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(0);
  const content = useRef<HTMLDivElement>(null);

  // function to control the state and height of the component (expanding, collapsing)
  const setDropdownHeight = (isExpanded: typeof expanded) => {
    const paddingTop = 24;
    const paddingBottom = 20;

    if (isExpanded) {
      setHeight(0);
    } else {
      setHeight((content.current?.scrollHeight || 0) + paddingTop + paddingBottom);
    }
  };

  /**
   * toggle dropdown
   */
  const toggleExpand = () => {
    setDropdownHeight(expanded);
    setExpanded(!expanded);
  };

  // INFO: to control dynamicly changing data
  useEffect(() => {
    setDropdownHeight(!expanded);
  }, [expanded, children]);

  useEffect(() => {
    if (defaultOpen) {
      toggleExpand();
    }
  }, []);

  return (
    <div className={`dropdown-wrapper  ${expanded ? 'expanded' : ''} ${wrapperClass}`}>
      <div className="dropdown-summary" onClick={toggleExpand} data-testid={summaryTestId}>
        <p>{summaryTitle}</p>
        <img src={customArrow || icon} alt="dropdown-icon" />
      </div>
      <div ref={content} style={{ maxHeight: height }} className="dropdown-content" data-testid={contentTestId}>
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
