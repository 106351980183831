/**
 * Created by Vladyslav Dubyna 29/02/24
 *
 * this is plus-minus component
 */

import React, { FC } from 'react';

import InputText from '../forms/InputText';
import { IInputTextProps } from '../../interfaces/IComponents';

export type PlusMinusCounterProps = Omit<IInputTextProps, 'onChange'> & {
  onChange?: (args: number) => void;
  keepToRight?: boolean;
  testId?: string;
  value?: number;
};

export const PlusMinusCounter: FC<PlusMinusCounterProps> = (props) => {
  const { title = '', min = 0, onChange = () => {}, onBlur = () => {}, keepToRight, testId, value = 0, className, ...rest } = props;

  // handling minus click change
  const onMinusChange = () => {
    const nextValue = value - 1;

    if (min !== undefined && nextValue < min) {
      return;
    }

    onChange(nextValue);
  };

  // handling plus click change
  const onPlusChange = () => {
    onChange(value + 1);
  };

  // handling manually input change
  const onInputTextChange = (number: number) => {
    onChange(Number(number));
  };

  // validator to avoid using values lower then 0 or 0
  const onInputBlur = () => {
    if (!value || (min && value < min)) {
      onChange(min as number);
    }
    onBlur();
  };

  return (
    <div className={`counter-wrapper ${className ?? ''}`} data-testid={testId}>
      {!!title && <div className="title">{title}</div>}
      <div className={`counter-content ${keepToRight ? 'moveRight' : ''}`}>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button className="button" onClick={onMinusChange} data-testid={`${testId}-minus`}>
          <div className="circle">
            <span className="minus-sign" />
          </div>
        </button>
        <InputText
          {...rest}
          testId={`${testId}-input`}
          onChange={onInputTextChange}
          onBlur={onInputBlur}
          className="counter-input"
          type="number"
          value={value}
          // noBottom
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button className="button" onClick={onPlusChange} data-testid={`${testId}-plus`}>
          <div className="circle plus">
            <span className="plus-sign" />
          </div>
        </button>
      </div>
    </div>
  );
};
