/**
 * Created by Ilya Savinov 29/01/24
 *
 * this is store for financing form
 */
import { makeAutoObservable } from 'mobx';

import { FinancingForm } from '../types/TGeneral';
import type RootStore from './RootStore';
import API from '../api/API';
import { CalculationParse } from '../api/v1/parse';
import { getFeaturesOptions } from '../pages/financingPageUtils';
import { CheckboxOption } from '../components/forms/Checkbox';
import pages from '../const/pages';

type FinancingFeature = CheckboxOption & {
  url: string;
};

class FinancingStore {
  private rootStore: RootStore;

  private static defaultFinancingForm = {
    paymentSelection: 'factoring',
    financingVia: '',
    financingDownPayment: 0,
    financingInterestRate: 0,
    financingTerm: 240,
    financingRateNet: 0,
    financingRateGross: 0,
    summaryPriceNet: 0,
    leasingDownPayment: 0,
    leasingTerm: 0,
    maximumLeasingValue: 0,
    leasingRateNet: 0,
    leasingRestValue: 0,
    leasingCommission: 0,
    leasingInterestRate: 0,
    hirePurchaseDownPayment: 0,
    hirePurchaseTerm: 0,
    rentingRate: 0,
    rentingCommission: 0,
    rentingInterestRate: 0,
    solarSpecial: false,
    bshEstate: false,
    offerUnitPrices: false,
    summaryPriceNetWithSolarSpecial: 0,
    discountInEurosSolarSpecial: 0,
    discountInPercent: 0,
    discountInEuros: 0,
    estateDescription: '',
    summaryPriceNetWithDiscount: 0,
  };

  private FeaturesOptions: FinancingFeature[];

  private FinancingForm: FinancingForm = FinancingStore.defaultFinancingForm;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;

    this.FeaturesOptions = getFeaturesOptions(this.rootStore.dataStore);
  }

  get financingForm() {
    return this.FinancingForm;
  }

  get featuresOptions() {
    return this.FeaturesOptions;
  }

  setFeatureOptions(
    newOptions: (CheckboxOption & {
      url: string;
    })[],
  ) {
    this.FeaturesOptions = newOptions;
  }

  /**
   * set financing form
   * @param newForm - new financing form
   */
  setFinancingForm(newForm: FinancingForm) {
    this.FinancingForm = newForm;
  }

  /**
   * load financing store on parse loading
   * @param calculationParse - calculation object from parse
   */
  loadStore(calculationParse: CalculationParse) {
    this.FinancingForm = {
      paymentSelection: calculationParse.paymentSelection ?? 'factoring',
      financingVia: calculationParse.financingVia,
      financingDownPayment: calculationParse.financingDownPayment,
      financingInterestRate: calculationParse.financingInterestRate,
      financingTerm: calculationParse.financingTerm ?? 240,
      financingRateNet: calculationParse.financingRateNet,
      financingRateGross: calculationParse.financingRateGross,
      summaryPriceNet: calculationParse.summaryPriceNet,
      leasingDownPayment: calculationParse.leasingDownPayment,
      leasingTerm: calculationParse.leasingTerm,
      maximumLeasingValue: calculationParse.maximumLeasingValue,
      leasingRateNet: calculationParse.leasingRateNet,
      leasingRestValue: calculationParse.leasingRestValue,
      leasingCommission: calculationParse.leasingCommission,
      leasingInterestRate: calculationParse.leasingInterestRate,
      hirePurchaseDownPayment: calculationParse.hirePurchaseDownPayment,
      hirePurchaseTerm: calculationParse.hirePurchaseTerm,
      rentingRate: calculationParse.rentingRate,
      rentingCommission: calculationParse.rentingCommission,
      rentingInterestRate: calculationParse.rentingInterestRate,
      solarSpecial: calculationParse.solarSpecial,
      bshEstate: calculationParse.bshEstate,
      offerUnitPrices: calculationParse.offerUnitPrices,
      summaryPriceNetWithSolarSpecial: calculationParse.summaryPriceNetWithSolarSpecial,
      discountInEurosSolarSpecial: calculationParse.discountInEurosSolarSpecial,
      discountInPercent: calculationParse.discountInPercent,
      discountInEuros: calculationParse.discountInEuros,
      estateDescription: calculationParse.estateDescription,
      summaryPriceNetWithDiscount: calculationParse.summaryPriceNetWithDiscount,
    };
  }

  /**
   * sync financing data in parse
   */
  async syncFinancingParseData() {
    const objectId = this.rootStore.dataStore.calculationObjectId;

    if (!objectId) {
      return;
    }

    await API.parse.syncCalculationObject(this.rootStore.dataStore.addCheckboxListMenu(this.FinancingForm, pages.financing), objectId);
  }

  setFeaturesOnPrice(options: FinancingFeature[], netPrices: { [key in string]: number }, leasingDownPayment: number) {
    const newFeatureOptions = options.map((option) => {
      // if (netPrices[option.url] && isWp && option.url === pages.hotWaterPump) {
      //   const isDisabled = leasingDownPayment > netPrices[option.url] * 0.3;

      //   return {
      //     ...option,
      //     checked: isDisabled ? false : option.checked,
      //     disabled: isDisabled,
      //   };
      // }

      if (netPrices[option.url] && option.url !== pages.photovoltaikQuote && option.url !== pages.hotWaterPump) {
        const isDisabled = leasingDownPayment > netPrices[option.url] * 0.3;

        return {
          ...option,
          checked: isDisabled ? false : option.checked,
          disabled: isDisabled,
        };
      }

      return option;
    });

    this.setFeatureOptions(newFeatureOptions);
  }

  resetStore() {
    this.FinancingForm = FinancingStore.defaultFinancingForm;
  }
}

export default FinancingStore;
