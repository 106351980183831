/**
 * Created by Max Gornostayev 02/15/22
 *
 * this is main component of the application
 */

import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { reaction } from 'mobx';
import { MobXProviderContext } from 'mobx-react';
import { useLocalObservable } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';

// import API from './api/API';
import ScrollToTop from './components/elements/ScrollToTop';
import Loading from './components/elements/Loading';
import RouteSecure from './core/RouteSecure';
import pageUrl from './const/pages';
import { IReactionApp } from './interfaces/ICommon';
import NotFoundPage from './pages/NotFoundPage';
import HomePage from './pages/HomePage';
import MissingRequiredDataPage from './pages/MissingRequiredDataPage';
import RootStore from './stores/RootStore';
import FormLayout from './layouts/FormLayout';
import 'react-toastify/dist/ReactToastify.css';
import './styles/app.scss';
import { routes } from './routes';

const App: React.FC = () => {
  const rootStore = useLocalObservable(() => new RootStore());
  const stores: RootStore = useMemo(() => rootStore, [rootStore]);
  // set state variables
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    stores.cacheStore.stopStore();
  }, [stores]);

  // determine the function for finishing loading stores
  const finishLoadingStores = ({ reactionUser, reactionCache }: IReactionApp) => {
    if (rootStore.userStore.isLoaded && reactionUser) {
      reactionUser.dispose();
    }
    if (rootStore.cacheStore.isLoaded && reactionCache) {
      reactionCache.dispose();
    }

    if (rootStore.userStore.isLoaded && rootStore.cacheStore.isLoaded) {
      setIsLoading(false);
    }
  };

  reaction(
    () => rootStore.userStore.isLoaded,
    (v1, v2, reactionUser) => finishLoadingStores({ reactionUser }),
  );
  reaction(
    () => rootStore.cacheStore.isLoaded,
    (v1, v2, reactionCache) => finishLoadingStores({ reactionCache }),
  );

  if (isLoading) {
    return <Loading isShow />;
  }

  return (
    <>
      <MobXProviderContext.Provider value={stores}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path={pageUrl.root} element={<HomePage />} />
            <Route path={pageUrl.page404} element={<NotFoundPage />} />
            <Route path="*" element={<Navigate replace to={pageUrl.root} />} />

            <Route element={<RouteSecure />}>
              <Route path={pageUrl.missingRequiredData} element={<MissingRequiredDataPage />} />
              <Route>
                {routes.map((route) => (
                  <Route key={route.path} path={route.path} element={<FormLayout>{route.element}</FormLayout>} />
                ))}
              </Route>
            </Route>
          </Routes>
        </Router>
      </MobXProviderContext.Provider>
      <ToastContainer className="toast-container" toastClassName="toast" closeButton={false} />
    </>
  );
};

export default App;
