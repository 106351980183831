/**
 * Created by Ilya Savinov 29/01/24
 *
 * this is store for memory extension data
 */
import { makeAutoObservable } from 'mobx';

import API from '../api/API';
import { MemoryExtensionForm } from '../types/TGeneral';
import type RootStore from './RootStore';
import { CalculationParse } from '../api/v1/parse';
import { getCheckboxListMenuFromSwitchers } from '../helpers/parse';
import pages from '../const/pages';

class MemoryExtensionStore {
  public static defaultMemoryExtensionForm = {
    memoryExtensionSelectionId: null,
    numberAdditionalBattery: 0,
    numberExistingBatteries: 0,
  };

  private rootStore: RootStore;

  private MemoryExtensionForm: MemoryExtensionForm = MemoryExtensionStore.defaultMemoryExtensionForm;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get memoryExtensionForm() {
    return this.MemoryExtensionForm;
  }

  get numberSummaryBatteries() {
    return this.MemoryExtensionForm.numberExistingBatteries + this.MemoryExtensionForm.numberAdditionalBattery;
  }

  /**
   * set memory extension form
   * @param newForm - new form
   */
  setMemoryExtensionForm(newForm: MemoryExtensionForm) {
    this.MemoryExtensionForm = newForm;
  }

  /**
   * load memory extension form to parse
   */
  async syncMemoryExtensionData() {
    const objectId = this.rootStore.dataStore.calculationObjectId;

    if (!objectId) {
      return;
    }

    const parseForm: any = {
      ...this.MemoryExtensionForm,
      memoryExtensionSelection: this.MemoryExtensionForm.memoryExtensionSelectionId,
      checkboxListMenu: getCheckboxListMenuFromSwitchers(this.rootStore.dataStore.formSwitchers),
      internalLastPageUrl: pages.memoryExtension,
    };

    delete parseForm.memoryExtensionSelectionId;

    await API.parse.syncCalculationObject(parseForm, objectId);
  }

  /**
   * set store on loading calculation data from parse
   */
  loadStore(calculationParse: CalculationParse) {
    this.MemoryExtensionForm = {
      memoryExtensionSelectionId: calculationParse.memoryExtensionSelection ?? null,
      numberExistingBatteries: calculationParse.numberExistingBatteries ?? 0,
      numberAdditionalBattery: calculationParse.numberAdditionalBattery ?? 0,
    };
  }
}

export default MemoryExtensionStore;
