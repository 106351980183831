/**
 * Created by Ilya Savinov 03/18/24
 *
 * this is bread crumbs menu component
 * @param currentLocation - string - current location
 * @param locations - string[] - active locations
 */
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IBreadCrumbsProps {
  currentLocation: string;
  locations: { [key in string]: string };
}

function BreadCrumbsMenu({ currentLocation, locations }: IBreadCrumbsProps) {
  const breakpointRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const urls = Object.keys(locations);

  const currentLocationIndex = urls.findIndex((location) => location === currentLocation);
  const progressWidth = `calc(100% * (${currentLocationIndex} / ${urls.length - 1}))`;

  const isOverlap = urls.length > 5;
  const isSingle = urls.length < 2;

  return (
    <div className="bread-crumbs-menu">
      <div className={`inner-container ${isSingle ? 'single' : ''}`}>
        {urls.map((url, index) => (
          <div
            className="breakpoint"
            key={url}
            ref={index === 0 ? breakpointRef : null}
            onClick={() => {
              if (index <= currentLocationIndex) {
                navigate(url);
              }
            }}
          >
            <div className={`circle ${index <= currentLocationIndex && 'ready'}`} />
            <span className={`breakpoint-title ${isOverlap && 'overlap-text'}`}>{locations[url]}</span>
          </div>
        ))}
        {!isSingle && (
          <>
            <div className="background-line" />
            <div className="progress-line" data-testid="progress-line" style={{ width: progressWidth }} />
          </>
        )}
      </div>
    </div>
  );
}

export default BreadCrumbsMenu;
