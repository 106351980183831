/**
 * Created by Ilya Savinov 13/02/24
 *

 * hook to navigate through bsh forms
 *
 */
import { useLocation, useNavigate } from 'react-router-dom';

import useStore from './useStore';

export const useBSHNavigation = () => {
  const navigation = useLocation();
  const navigate = useNavigate();
  const { dataStore } = useStore();

  /**
   * go to previous form based on toggle switchers
   */
  const goToPreviousPage = () => {
    const possiblePaths = dataStore.formSwitchers.filter((switcher) => switcher.checked);

    const currentPosition = possiblePaths.findIndex((switcher) => navigation.pathname.includes(switcher.url ?? ''));

    const previousUrl = (possiblePaths[currentPosition - 1] || {}).url || null;

    if (previousUrl) {
      navigate(previousUrl);
    }
  };

  /**
   * get next url
   */
  const nextUrl = () => {
    const switchers = dataStore.formSwitchers;
    const possiblePaths = switchers.filter((switcher) => switcher.checked);

    const currentPosition = possiblePaths.findIndex((switcher) => navigation.pathname.includes(switcher.url ?? ''));

    return (possiblePaths[currentPosition + 1] || {}).url || null;
  };

  /**
   * go to next form based on toggle switchers
   */
  const goToNextPage = () => {
    const url = nextUrl();

    if (url) {
      navigate(url);
    }

    return null;
  };

  /**
   * get is current form last in calculator
   */
  const isCurrentFormLast = (pageUrl: string) => {
    const possiblePaths = dataStore.formSwitchers.filter((switcher) => switcher.checked);
    const currentIndex = possiblePaths.findIndex((form) => form.checked && form.url === pageUrl);

    return currentIndex === possiblePaths.length - 1;
  };

  return { nextUrl: nextUrl(), goToNextPage, goToPreviousPage, isCurrentFormLast };
};
