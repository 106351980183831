/**
 * Created by Max Gornostayev 3/17/23
 *
 * Help library
 */

import config from '../config';
import { SelectOption } from '../interfaces/IComponents';

const Utils = {
  /*
   * make right format of price value
   */
  priceProcess: (price: string): string => {
    if (!price) {
      return price;
    }
    const t = new RegExp(`\\${config.digit.separator1000}`, 'g');
    const d = new RegExp(`\\${config.digit.decimalSeparator}`, 'g');

    return price.replace(t, '').replace(d, '.');
  },

  extractNumber(s: string): number {
    const match = s.match(/\d+/); // This regex matches the sequence of digits

    return match ? parseInt(match[0], 10) : 0;
  },

  customSort(a: SelectOption, b: SelectOption): number {
    const extractNumbersFromEnd = (label: string): number[] => {
      const regex = /\d+(\.\d+)?/g;
      const matches = label.match(regex);

      return matches ? matches.map((match) => parseFloat(match)) : [Infinity];
    };

    // Extract numbers from the end of the labels
    const aNumbers = extractNumbersFromEnd(a.label);
    const bNumbers = extractNumbersFromEnd(b.label);

    // Compare brand names first (alphabetically)
    const brandA = a.label.split(' ')[0];
    const brandB = b.label.split(' ')[0];

    if (brandA < brandB) return -1;
    if (brandA > brandB) return 1;

    // If brand names are equal, compare the numbers from the end of the labels
    for (let i = 0; i < Math.max(aNumbers.length, bNumbers.length); i++) {
      const aNumber = aNumbers[i] !== undefined ? aNumbers[i] : Infinity;
      const bNumber = bNumbers[i] !== undefined ? bNumbers[i] : Infinity;

      if (aNumber < bNumber) return -1;
      if (aNumber > bNumber) return 1;
    }

    return 0;
  },

  b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });

    return blob;
  },

  openPdf(b64Data: string) {
    // Download
    // const downloadLink = document.createElement('a');

    // downloadLink.href = `data:application/octet-stream;base64,${b64Data}`;
    // downloadLink.download = 'energy_photovoltaik.pdf';
    // downloadLink.click();

    const blob = Utils.b64toBlob(b64Data, 'application/pdf');
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl);
  },

  formatPrice(value: number): string {
    // Create a new instance of Intl.NumberFormat for the 'de-DE' locale,
    // which uses periods for thousands and commas for decimal points.
    const formatter = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2, // Ensure that we always have two decimal places
      maximumFractionDigits: 2, // Maximum of two decimal places
    });

    // Use the formatter to format the number and return it
    return formatter.format(value);
  },

  // Convert a file to FormData
  fileToFormData(file: File): FormData {
    const formData = new FormData();

    formData.append('file', file);

    return formData;
  },

  /*
   * add dots to the string and cut it
   */
  addDotsToString: (str: string, len: number = 30): string => (str.length > len ? `${str.substr(0, len)}...` : str),

  /*
   * format number
   * n: value of number
   * c: number of digits after dot
   * d: decimal separator
   * t: separator for 1000
   */
  formatNumber(n: number, c: number = 0, d: string = config.digit.decimalSeparator, t: string = config.digit.separator1000): string {
    const s = n < 0 ? '-' : '';
    const newVal = Math.abs(n).toFixed(c);
    const i = String(parseInt(newVal, 10));
    let j = i.length;

    j = j > 3 ? j % 3 : 0;

    return (
      s +
      (j ? i.substr(0, j) + t : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
      (c
        ? d +
          Math.abs(n - Number(i))
            .toFixed(c)
            .slice(2)
        : '')
    );
  },

  /*
   * get query params from window
   * @param paramNAme - string - param name
   */
  getQueryParam(paramName: string): string {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return urlParams.get(paramName) || '';
  },
};

export default Utils;
