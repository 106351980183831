/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is API requests lists from broker crm server
 */
import CustomAPI from '../CustomAPI';
import { APIResponse } from '../../types/TGeneral';
import { ISyncDataResponse } from '../../interfaces/IParseFunc';
import { toastManager } from '../../lib/ToastManager';

const Sync = {
  /*
   * get cache data from server
   */
  getData: async (): Promise<APIResponse<ISyncDataResponse>> => {
    const res = await CustomAPI.request('post', '/parse/functions/config', {}, {}, 30000);

    return res as APIResponse<ISyncDataResponse>;
  },

  resetCache: async (): Promise<void> => {
    await CustomAPI.request('post', '/workflow/resync-config', {}, {}, 300000);
  },

  /**
   * request to generate pdf for Calculation object
   * @param calculationParseId - string - object id in Calculation table
   */
  generatePdfQuote: async (calculationParseId: string) => {
    try {
      const result = await CustomAPI.request('post', `/workflow/generate-quote-pdf/${calculationParseId}`, {}, {}, 300000);

      return result as APIResponse<any>;
    } catch (error: any) {
      toastManager.showError(error.message || 'Pdf-Generierung ist fehlgeschlagen');
    }

    return null;
  },
};

export default Sync;
