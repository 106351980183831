/**
 * Created by Ilya Savinov 29/01/24
 *
 * this is blackout box page
 */
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import ContentArea from '../components/theme/ContentArea';
import i18n from '../i18n';
import Select from '../components/forms/Select';
import Bottom from '../components/theme/Bottom';
import Button from '../components/buttons/Button';
import useStore from '../hooks/useStore';
import { SelectOption } from '../interfaces/IComponents';
import { InputRefType } from '../components/forms/InputText';
import Validation from '../lib/Validation';
import { useBSHNavigation } from '../hooks/useBSHNavigation';
import pages from '../const/pages';
import Tooltip from '../components/elements/Tooltip';
import Utils from '../lib/Utils';

const BlackoutBoxPage = observer(() => {
  const { goToNextPage, goToPreviousPage, isCurrentFormLast } = useBSHNavigation();
  const { cacheStore, dataStore, photovoltaikStore } = useStore();
  const { blackoutBoxSelectionId, formSwitchers, isPdfLoading, isGenerateQuoteEnable, isGenerateQuoteValidated } = dataStore;
  const { storages } = photovoltaikStore;

  const [nextButtonTooltip, setNextButtonTooltip] = useState(false);

  const selectRef = useRef<InputRefType>(null);

  useEffect(() => {
    const refValue = selectRef.current;

    if (refValue) {
      const validObj = refValue.inputValidate(blackoutBoxSelectionId ?? '');

      if (!validObj.validated) {
        refValue.setValidation(validObj);
      }
    }
  }, [blackoutBoxSelectionId]);

  // get options for blackout box select
  const getBlackoutBoxOptions = () => {
    const blackoutBoxProducts = cacheStore.getProductsByGroupId('blackoutBox');

    const isPhotovoltaic = formSwitchers.find((form) => form.url === pages.photovoltaikQuote && form.checked);

    // filter by storage brand if selected
    if (isPhotovoltaic) {
      if (storages) {
        const sameBrand = blackoutBoxProducts?.filter((box) => box.brandId === storages?.brandId);

        return (
          sameBrand?.map((prod) => ({
            id: prod.serviceId,
            label: prod.name,
          })) ?? []
        );
      }

      // TODO remove in September
      // if (internalCalculatedStorageValue) {
      //   const calculatedStorage = cacheStore.getProductById(internalCalculatedStorageValue);
      //   const sameBrand = blackoutBoxProducts?.filter((box) => box.brandId === calculatedStorage?.brandId);

      //   return (
      //     sameBrand?.map((prod) => ({
      //       id: prod.serviceId,
      //       label: prod.name,
      //     })) ?? []
      //   );
      // }
    }

    return (
      blackoutBoxProducts?.map((prod) => ({
        id: prod.serviceId,
        label: prod.name,
      })) ?? []
    );
  };

  const blackoutBoxSelection = cacheStore.getProductById(blackoutBoxSelectionId ?? '');

  const selectValue = blackoutBoxSelection ? { label: blackoutBoxSelection.name, id: blackoutBoxSelection.id } : null;

  const isFormLast = isCurrentFormLast(pages.blackoutBox);

  // handle select value change
  const handleSelectChange = (option: SelectOption) => {
    const newValue = option.id === '' ? null : option.id.toString();

    dataStore.setBlackoutBoxSelectionId(newValue);
  };

  // handle next click change
  const handleNextClick = async () => {
    if (isFormLast) {
      dataStore.createQuote(pages.blackoutBox);

      return;
    }

    if (blackoutBoxSelection) {
      dataStore.syncBlackoutBoxParseData();
      goToNextPage();
    }
  };

  const selectionOptions = [{ id: '', label: i18n.t('photovoltaikPage.noSelection') }, ...getBlackoutBoxOptions().sort(Utils.customSort)];

  return (
    <ContentArea>
      <div className="content-main">
        <div className="wallbox-title-row">
          <h2 className="wallbox-title">{i18n.t('forms.blackoutBox').toUpperCase()}</h2>
        </div>
        <div className="blackout-select-container">
          <Select
            ref={selectRef}
            value={selectValue}
            title={`${i18n.t('common.quantity')} Blackout Box`}
            placeholder={i18n.t('photovoltaikPage.noSelection')}
            options={selectionOptions}
            onSuggestionSelected={handleSelectChange}
            testId="blackoutBox-select"
            validateFunc={(p) => Validation.emptyValue(p, i18n.t('validations.fieldRequired'))}
          />
        </div>
      </div>
      <Bottom>
        <Button role="btnCancel" text={i18n.t('buttons.back')} onClick={goToPreviousPage} />
        <Tooltip
          isShow={nextButtonTooltip && !blackoutBoxSelectionId}
          text={i18n.t('validations.validateNextButton')}
          position="top"
          bottomButtons
        >
          <Button
            role="btnNext"
            text={isFormLast ? i18n.t('buttons.createNewOffer') : i18n.t('buttons.next')}
            isGreen
            leftMargin20
            onClick={handleNextClick}
            testId="next-button"
            isDisabled={isFormLast ? !isGenerateQuoteEnable || !isGenerateQuoteValidated || isPdfLoading : !blackoutBoxSelectionId}
            onMouseEnter={() => setNextButtonTooltip(true)}
            onMouseLeave={() => setNextButtonTooltip(false)}
          />
        </Tooltip>
      </Bottom>
    </ContentArea>
  );
});

export default BlackoutBoxPage;
