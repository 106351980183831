/**
 * Created by Max Gornostayev 12/28/23
 *
 * this is a bottom
 */
import React from 'react';

import { IGeneralProps } from '../../interfaces/IComponents';

export default function Bottom({ children }: IGeneralProps) {
  return (
    <div className="bottom" role="bottom">
      {children}
    </div>
  );
}
