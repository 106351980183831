/**
 * Created by Max Gornostayev on 01/15/24
 *
 * this is a store for cache data
 */

import { makeAutoObservable, action, runInAction } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';
import { Calculator, IProduct } from 'bsh-calculator';

import API from '../api/API';
import config from '../config';
import { ICache, ICacheBrand, ICacheGroup } from '../interfaces/ICache';
import Logger from '../core/Logger';
import Utils from '../lib/Utils';
import type RootStore from './RootStore';

class CacheStore {
  private rootStore: RootStore;

  // json string to store cache in the local storage
  cache = '';

  // lists of products
  products = new Map<number, IProduct>();

  // lists of groups
  groups = new Map<string, ICacheGroup>();

  // lists of brands
  brands = new Map<string, ICacheBrand>();

  calculator: Calculator = new Calculator();

  // is store loaded or not
  isLoaded = false;

  // constructor
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;

    makePersistable(this, {
      name: 'BSH2CacheStore',
      properties: ['cache'],
      storage: window.localStorage,
      removeOnExpiration: true,
      expireIn: config.storesExpiration.data,
      debugMode: false,
    }).then(
      action(async () => {
        await this.loadData();
      }),
    );
  }

  /*
   * load data
   */
  async loadData(reset = false) {
    const isCacheLoaded = this.loadFromCache();
    const isCached = isCacheLoaded && !!this.products.size && !!this.brands.size && !!this.groups.size;

    if (!isCached || reset) {
      await this.loadDataFromServer();
      runInAction(() => this.updateCache());
    }
    runInAction(() => this.setIsLoaded(true));
    Logger.debug(this.isLoaded, 'CacheStore - LOADED::::');
  }

  async resetCache() {
    await API.sync.resetCache();
    await this.loadData(true);
  }

  /*
   * set loaded store or no
   */
  setIsLoaded(value: boolean) {
    this.isLoaded = value;
  }

  /*
   * load from cache string, return true if cache is loaded
   * @return bool
   */
  loadFromCache(): boolean {
    try {
      const { cache } = this;

      if (cache) {
        const obj: ICache = JSON.parse(this.cache) as ICache;

        runInAction(() => this.setData(obj));

        return true;
      }
    } catch (e) {
      return false;
    }

    return false;
  }

  /*
   * load cache data from server
   */
  async loadDataFromServer() {
    const token = Utils.getQueryParam('token') || this.rootStore.userStore.authToken;

    if (!token) {
      return;
    }

    const res = await API.sync.getData();

    if (res.isSucceed) {
      if (!Array.isArray(res.data)) {
        const { products, productGroups, productBrands } = res.data;

        this.calculator = new Calculator(products, productBrands, this.rootStore.userStore.customer.type);

        runInAction(() => this.setData({ products, groups: productGroups, brands: productBrands } as ICache));
      }
    }
  }

  /*
   * set data object
   */
  setData(data: ICache) {
    this.products.clear();
    this.groups.clear();
    this.brands.clear();
    data.products.map((item) => this.products.set(item.id, item));
    data.groups.map((item) => this.groups.set(item.serviceId, item));
    data.brands.map((item) => this.brands.set(item.serviceId, item));
  }

  /*
   * update cache string
   */
  updateCache() {
    const obj = this.createCacheObject();

    this.cache = JSON.stringify(obj);
  }

  /*
   * create cache object
   * @return ICache
   */
  createCacheObject(): ICache {
    const cacheObj: ICache = {
      products: Array.from(this.products.values()),
      groups: Array.from(this.groups.values()),
      brands: Array.from(this.brands.values()),
    };

    return cacheObj;
  }

  /*
   * get product by id
   * @bitrixId - product id
   * @groupId - groups label
   * isActive - filter by product active property
   */
  getProductById(bitrixId: string, groupId?: string, isActive = false): Nullable<IProduct> {
    const product = Array.from(this.products.values()).find((prod) => {
      const idEqual = prod.serviceId.toString() === bitrixId;
      let groupEqual = true;
      let active = true;

      if (groupId) {
        const group = this.groups.get(groupId);

        groupEqual = group?.id === Number(prod.groupId);
      }

      if (isActive) {
        active = prod.isActive;
      }

      return idEqual && groupEqual && active;
    });

    return product ?? null;
  }

  /*
   * get products by id
   * @bitrixIds - product ids
   * @groupId - groups label
   * isActive - filter by product active property
   */
  getProductByIds(bitrixIds: string[], groupId?: string, isActive = false): Nullable<IProduct[]> {
    const products = Array.from(this.products.values()).filter((prod) => {
      const idEqual = bitrixIds.includes(prod.id.toString());
      let groupEqual = true;
      let active = true;

      if (groupId) {
        const group = this.groups.get(groupId);

        groupEqual = group?.id === Number(prod.groupId);
      }

      if (isActive) {
        active = prod.isActive;
      }

      return idEqual && groupEqual && active;
    });

    return products ?? null;
  }

  /*
   * get product by group label
   * @serviceId - groups label
   */
  getProductsByGroupId(groupId: string): Nullable<IProduct[]> {
    const group = this.groups.get(groupId);

    const products = Array.from(this.products.values()).filter((prod) => group?.id === Number(prod.groupId) && prod.isActive);

    return products.length ? products : null;
  }

  /*
   * get brands of products
   */
  getBrands(): Nullable<ICacheBrand[]> {
    return Array.from(this.brands.values()).filter((brand) => brand.isActive);
  }

  get storageBrands(): Nullable<ICacheBrand[]> {
    const brands = this.getBrands();

    const storageBrands = brands?.filter((brand) => {
      let stgs = this.getProductsByGroupId('storage') ?? [];

      stgs = stgs?.filter((st) => st.brandId === brand.id.toString());

      return stgs.length > 0;
    });

    return storageBrands ?? [];
  }

  getStorageSelectOptions(storageBrandId: Nullable<string>) {
    const storageBrand = this.getBrandById(storageBrandId ?? '');
    let stgs = this.getProductsByGroupId('storage') ?? [];

    if (storageBrand) {
      stgs = stgs?.filter((st) => st.brandId === storageBrand.id.toString());
    }

    return stgs?.map((product) => ({ id: product.id, label: product.name })) ?? [];
  }

  getBrandById(bitrixId: string) {
    return this.brands.get(bitrixId) ?? null;
  }

  /*
   * stop persisting
   */
  stopStore() {
    stopPersisting(this);
  }
}

// const cacheStore = new CacheStore();
export default CacheStore;
