/**
 * Created by Max Gornostayev on 12/15/23
 *
 * this is parent class for API requests
 */

/* eslint-disable class-methods-use-this */

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import Logger from '../core/Logger';
import codes from '../const/codes';
import i18next from '../i18n';
import { APIFuncResponse, APIResponse } from '../types/TGeneral';
import config from '../config';
import Utils from '../lib/Utils';
import { toastManager } from '../lib/ToastManager';

class CustomAPI {
  static instance: CustomAPI;

  static getInstance() {
    if (!CustomAPI.instance) {
      CustomAPI.instance = new CustomAPI();
    }

    return CustomAPI.instance;
  }

  axiosInstance: AxiosInstance = {} as AxiosInstance;

  constructor() {
    this.setAxiosInstance();
    this.setJWTToken();
  }

  /**
   * This is a function that sends a request to the BrokerCRM server
   *
   * @param method - string - method of the request like POST, GET, etc
   * @param path - string - the path of endpoint
   * @param data - object - params of the body {'name': value, ...}
   * @param params - object - params of the body {'name': value, ...}
   * @param skipRefreshing - bool - should we skip step for authorization or no
   * @param timeout - number - timeout of the request
   * @return object of the response, check super.getResponseObj
   */
  async request<T>(
    method: string,
    url: string,
    data: { [index: string]: Nullable<any> } = {},
    params: { [index: string]: string | number } = {},
    timeout: number = config.api.timeoutFunc,
    headers: { [index: string]: string } = {},
  ): Promise<APIResponse<T>> {
    // forming params for the request
    const conf: AxiosRequestConfig = {
      method,
      url,
      timeout,
      headers,
    };

    if (data) conf.data = data;
    if (params) conf.params = params;

    // process a response and return a data if it's possible
    const ret = (await this.sendRequest(conf)) as APIResponse<T>;

    return ret;
  }

  /*
   * send request
   */
  setAxiosInstance(baseURL?: string, contentType: string = 'application/json') {
    const params: AxiosRequestConfig = {
      baseURL: baseURL || process.env.REACT_APP_GATEWAY_URL,
      headers: {
        'Content-Type': contentType,
        'X-Parse-Application-Id': process.env.REACT_APP_PARSE_FUNC_ID || '',
      },
    };

    this.axiosInstance = axios.create(params);

    this.axiosInstance.interceptors.response.use(
      (response) => {
        Logger.debug(response, '------------REQUEST SUCCESS--------------');

        return response;
      },
      (error) => {
        Logger.error(error, '------------REQUEST FAILED--------------');

        const errorMessage = error?.response?.data?.result?.message || '';

        toastManager.showError(errorMessage || error.message);

        return Promise.reject(error);
      },
    );
  }

  /*
   * send request
   */
  sendRequest<T>(requestParams: AxiosRequestConfig): Promise<APIResponse<T>> {
    return this.axiosInstance
      .request(requestParams)
      .then((response: AxiosResponse<APIFuncResponse<T>>) => this.success(response))
      .catch((e) => this.error(e)) as Promise<APIResponse<T>>;
  }

  /*
   * proccess a response if it's success
   */
  success<T>(response: AxiosResponse<APIFuncResponse<T>>): APIResponse<T> {
    const realData = response.data.result ? response.data.result.data : response.data.results;

    const result = realData ?? response.data;

    return this.getResponseObj(true, response.status, result);
  }

  // TODO: add error handling
  /*
   * proccess a response if it's error
   */
  error<T>(error: any): APIResponse<T> {
    if (!error.response) {
      // process timeout
      return this.getResponseObj(false, codes.statusTimeout);
    }
    let code = error.response.status ? error.response.status : null;
    let data = error.response.data ? error.response.data : null;

    if (data && data.data) {
      data = data.data;
    }
    if (!code && data && data.code) {
      code = data.code;
    }
    const msg = this.getErrorMsg(code, data);

    return this.getResponseObj(false, code, data, msg);
  }

  /*
   * post proccess a response
   */
  getErrorMsg(code: number, data: any): string {
    let msg = data && data.id ? i18next.t(`serverErrorIds.${data.id}`) : '';

    if (!msg) {
      switch (code) {
        case codes.statusTimeout:
          msg = i18next.t('codeResponsesAPI.statusTimeout');
          break;
        case codes.statusNotAuthorize:
          msg = i18next.t('codeResponsesAPI.statusNotAuthorize');
          break;
        case codes.statusBadRequest:
        case codes.statusNotFound:
          msg = i18next.t('codeResponsesAPI.statusBadRequest');
          break;
        case codes.statusInternalError:
        case codes.statusInternalServerError:
        default:
          msg = i18next.t('codeResponsesAPI.statusInternalServerError');
          break;
      }
    }

    return msg;
  }

  /*
   * form response object
   * @param isSucceed - bool - isSucceed of the response
   * @param code - number - code of the response
   * @param data - object - that is returned
   * @param msg - string - message of the response
   * @return object
   */
  getResponseObj<T>(
    isSucceed: boolean = false,
    code: number = codes.statusInternalError,
    data: any = {},
    msg: string = '',
  ): APIResponse<T> {
    const ret: APIResponse<T> = {
      isSucceed,
      code,
      data,
      msg,
    };

    return ret;
  }

  /*
   * set common header in axios instance
   */
  setHeaderCommon(id: string, value: string) {
    this.axiosInstance.defaults.headers.common[id] = value;
  }

  /*
   * Set jwt token
   *
   * @param token - string - this is JWT token that is used in the gateway
   */
  setJWTToken() {
    const token = Utils.getQueryParam('token');

    this.setHeaderCommon('Authorization', `Bearer ${token}`);
  }
}

const apiInstance = CustomAPI.getInstance();

export default apiInstance;
