const codes = {
  statusOK: 200,
  statusOKFinAPI: 201,
  statusNoContent: 204,

  statusBadRequest: 400,
  statusNotAuthorize: 401,
  statusNotPermission: 403,
  statusNotFound: 404,
  statusNoInternet: 490,
  statusInternalServerError: 500,
  statusInternalError: 1000,
  statusTimeout: 2000,
  statusVersionNotSupported: 5000,
};

export default codes;
