/**
 * Created by Max Gornostayev 01/15/24
 *
 * this is a right panel
 */
import React from 'react';

import { IGeneralProps } from '../../interfaces/IComponents';

export default function Right({ children }: IGeneralProps) {
  return (
    <div className="right-area" role="rightArea">
      {children}
    </div>
  );
}
