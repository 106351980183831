/**
 * Created by Ilya Savinov 29/01/24
 *
 * this is store for general data
 */
import { makeAutoObservable, runInAction } from 'mobx';
import { ProductResultType, ProductResultErrorType } from 'bsh-calculator';
import omit from 'lodash/omit';

import config from '../config';
import { FormSwitcher } from '../interfaces/ICommon';
import i18n from '../i18n';
import API from '../api/API';
import Utils from '../lib/Utils';
import pages from '../const/pages';
import { HotWaterHeatPumpQuoteWidget, QuotePdf, StoragesExtensionForm, WallBoxForm } from '../types/TGeneral';
import type RootStore from './RootStore';
import { CrmAddress } from '../interfaces/IN8N';
import { getCheckboxListMenuFromSwitchers, getSwitchersFromCheckboxMenu } from '../helpers/parse';
import { toastManager } from '../lib/ToastManager';

type FinancingCalculations = { [key in string]: { summaryPriceGross: number; summaryPriceNet: number } };

class DataStore {
  private rootStore: RootStore;

  private static defaultSwitchers = config.forms.map((formConfig) => ({
    labelTranslation: formConfig.label,
    disabled: false,
    checked: formConfig.url === pages.basicData || formConfig.url === pages.financing || formConfig.url === pages.photovoltaikQuote,
    url: formConfig.url,
  }));

  private static defaultWallboxForm = {
    wallboxSelectionId: null,
    wallboxQuantity: 1,
    selectionJuiceBooster2: false,
    selectionSteleSenecPlus: false,
    selectionSteleSenecPremium: false,
  };

  private static defaultWaterPumpForm = {
    selectionHotWaterHeatPumpId: null,
    centralHeatingExist: false,
  };

  private static defaultStorageExtensionForm = {
    storageExtensionSelectionId: null,
  };

  private CrmDealId: string = '';

  public calculationObjectId: Nullable<string> = '';

  /*
   * switchers for filters on the left sidebar
   */
  private switchers: FormSwitcher[] = DataStore.defaultSwitchers;

  private IsMenuExpended = true;

  private IsGenerateQuoteEnable = false;

  private products: Nullable<{ [key in string]: { count: number } }> = null;

  private IsPdfLoading: boolean = false;

  private WallboxForm: WallBoxForm = DataStore.defaultWallboxForm;

  private PdfQuote: Nullable<QuotePdf> = null;

  private InternalObjectAddress: Nullable<CrmAddress> = null;

  private financingCalculations: Nullable<FinancingCalculations> = null;

  private BlackoutBoxSelectionId: Nullable<string> = null;

  private HotWaterForm: HotWaterHeatPumpQuoteWidget = DataStore.defaultWaterPumpForm;

  private StorageExtensionForm: StoragesExtensionForm = DataStore.defaultStorageExtensionForm;

  public lastPageUrl: Nullable<string> = null;

  private loanCalculations: { upfrontPayment: number; totalCost: number };

  constructor(rootStore: RootStore) {
    const dealId = Utils.getQueryParam('dealId') ?? '';

    this.loanCalculations = { totalCost: 0, upfrontPayment: 0 };
    this.CrmDealId = dealId;

    this.rootStore = rootStore;

    makeAutoObservable(this);

    this.loadCalculationData();
  }

  get formSwitchers() {
    return this.switchers;
  }

  get isMenuExpended() {
    return this.IsMenuExpended;
  }

  get isGenerateQuoteEnable() {
    return this.IsGenerateQuoteEnable;
  }

  get crmDealId() {
    return this.CrmDealId;
  }

  // get bread crumbs
  get crumbs() {
    const filterCrumbs = this.switchers.filter((form) => form.checked);
    const translatedCrumbs = (filterCrumbs ?? []).reduce<Record<string, string>>((acc, curr) => {
      acc[curr.url] = i18n.t(curr.labelTranslation);

      return acc;
    }, {});

    return translatedCrumbs;
  }

  // is create offer button available
  get isGenerateQuoteValidated() {
    const possiblePaths = this.formSwitchers.filter((switcher) => switcher.checked);

    const formConditions = [];

    const showPhotovoltaik = !!possiblePaths.find((item) => item.url === pages.photovoltaikQuote);
    const showHotWater = !!possiblePaths.find((item) => item.url === pages.hotWaterPump);
    const showWallbox = !!possiblePaths.find((item) => item.url === pages.wallboxQoute);
    const showBlackoutBox = !!possiblePaths.find((item) => item.url === pages.blackoutBox);
    const showMemoryExtension = !!possiblePaths.find((item) => item.url === pages.memoryExtension);
    const showStorageExtension = !!possiblePaths.find((item) => item.url === pages.storageExtension);
    // const showFinancing = !!possiblePaths.find((item) => item.url === pages.financing);

    if (showPhotovoltaik) {
      const isValidated =
        this.rootStore.photovoltaikStore.moduleAreaForms.every(
          (form) =>
            !!form.moduleAreaDirectionId &&
            !!form.moduleAreaLabel &&
            !!form.moduleAreaProduction &&
            !!form.moduleAreaSize &&
            !!form.moduleAreaTypes?.length &&
            !!form.numbersOfModules,
        ) &&
        !!this.rootStore.photovoltaikStore.storagesId &&
        !!this.rootStore.photovoltaikStore.modulTypeId;

      formConditions.push(isValidated);
    }

    if (showHotWater) {
      const isValidated = !this.HotWaterForm.centralHeatingExist || this.HotWaterForm.selectionHotWaterHeatPumpId;

      formConditions.push(isValidated);
    }

    if (showWallbox) {
      formConditions.push(!!this.WallboxForm.wallboxSelectionId);
    }

    if (showBlackoutBox) {
      formConditions.push(!!this.blackoutBoxSelectionId);
    }

    if (showMemoryExtension) {
      formConditions.push(!!this.rootStore.memoryExtensionStore.memoryExtensionForm.memoryExtensionSelectionId);
    }

    if (showStorageExtension) {
      formConditions.push(!!this.StorageExtensionForm.storageExtensionSelectionId);
    }

    return formConditions.every((value) => value);
  }

  get wallboxForm() {
    return this.WallboxForm;
  }

  get blackoutBoxSelectionId() {
    return this.BlackoutBoxSelectionId;
  }

  get hotWaterForm() {
    return this.HotWaterForm;
  }

  get storageExtensionForm() {
    return this.StorageExtensionForm;
  }

  get pdfQuote() {
    return this.PdfQuote;
  }

  get isPdfLoading(): boolean {
    return this.IsPdfLoading;
  }

  get internalObjectAddress() {
    return this.InternalObjectAddress;
  }

  /**
   * load store on parse request
   */
  async loadCalculationData() {
    const dealId = Utils.getQueryParam('dealId');

    if (!dealId) {
      return;
    }

    const pdfQuoteRes = this.getPdfQuote(dealId);
    const calculationParse = await API.parse.getCalculationData(dealId);

    if (calculationParse) {
      this.rootStore.photovoltaikStore.loadStore(calculationParse);
      this.rootStore.financingStore.loadStore(calculationParse);
      this.rootStore.memoryExtensionStore.loadStore(calculationParse);

      this.calculationObjectId = calculationParse.objectId;

      if (calculationParse.checkboxListMenu) {
        this.switchers = getSwitchersFromCheckboxMenu(this.switchers, calculationParse.checkboxListMenu);
      }

      this.HotWaterForm = {
        selectionHotWaterHeatPumpId: calculationParse.selectionHotWaterHeatPump,
        centralHeatingExist: calculationParse.centralHeatingExist,
      };

      this.WallboxForm = {
        wallboxSelectionId: calculationParse.wallboxSelection ?? null,
        wallboxQuantity: calculationParse.wallboxQuantity ?? 1,
        selectionJuiceBooster2: calculationParse.selectionJuiceBooster2,
        juiceBooster2Quantity: calculationParse.juiceBooster2Quantity,
        selectionSteleSenecPlus: calculationParse.selectionSteleSenecPlus,
        steleSenecPlusQuantity: calculationParse.steleSenecPlusQuantity,
        selectionSteleSenecPremium: calculationParse.selectionSteleSenecPremium,
        steleSenecPremiumQuantity: calculationParse.steleSenecPremiumQuantity,
      };

      this.BlackoutBoxSelectionId = calculationParse.blackoutBoxSelection ?? null;

      this.StorageExtensionForm = {
        storageExtensionSelectionId: calculationParse.storageExtensionSelection ?? null,
      };

      this.lastPageUrl = calculationParse.internalLastPageUrl ?? null;
    }

    await pdfQuoteRes;
  }

  /**
   * load calculated widget products to parse
   */
  async syncParseProducts() {
    if (this.products && this.calculationObjectId) {
      await API.parse.syncCalculationObject(
        {
          products: this.products,
          loanCalculations: this.loanCalculations,
          libCalculatedData: {
            ...this.rootStore.photovoltaikStore.summaryModuleAreaForm,
            financingCalculations: this.financingCalculations,
            numberSummaryBatteries: this.rootStore.memoryExtensionStore.numberSummaryBatteries,
            electricityPrice: this.rootStore.photovoltaikStore.electricityPrice,
          },
        },
        this.calculationObjectId,
      );
    }
  }

  /**
   * load missing data to parse
   */
  async syncMissingDataParse() {
    // Sync Customer parse data
    await this.rootStore.userStore.syncCustomerParseData();

    if (!this.calculationObjectId) {
      const newCalculationObject = {
        ...this.rootStore.photovoltaikStore.profitabilityCalculationForm,
        internalObjectAddress: this.InternalObjectAddress,
        crmDealId: this.CrmDealId,
        customerObjectId: this.rootStore.userStore.customer.objectId,
        quoteNumber: this.rootStore.userStore.quoteNumber,
        crmResponsibleUserId: this.rootStore.userStore.customer.crmResponsibleUserId ?? '',
      };

      const res = await API.parse.createCalculationForm(this.addCheckboxListMenu(newCalculationObject, pages.basicData));

      if (res && !Array.isArray(res.data)) {
        const calculationObjectId = res.data?.objectId ?? '';

        // Update address in crm
        API.deal.syncCrmAddress(calculationObjectId);

        this.calculationObjectId = calculationObjectId;
      }
    } else {
      await API.parse.syncCalculationObject(
        {
          internalObjectAddress: this.internalObjectAddress,
          crmResponsibleUserId: this.rootStore.userStore.customer.crmResponsibleUserId ?? '',
        },
        this.calculationObjectId,
      );

      API.deal.syncCrmAddress(this.calculationObjectId);
    }
  }

  /**
   * sync basic parse data
   */
  async syncBasicParseData() {
    await this.rootStore.userStore.syncCustomerParseData();

    const calculationObject = {
      ...this.rootStore.photovoltaikStore.profitabilityCalculationForm,
      internalObjectAddress: this.InternalObjectAddress,
      crmResponsibleUserId: this.rootStore.userStore.customer.crmResponsibleUserId ?? '',
      customerObjectId: this.rootStore.userStore.customer.objectId,
      quoteNumber: this.rootStore.userStore.quoteNumber,
      crmDealId: this.CrmDealId,
    };

    if (!this.calculationObjectId) {
      const res = await API.parse.createCalculationForm(this.addCheckboxListMenu(calculationObject, pages.basicData));

      if (res && !Array.isArray(res.data)) {
        const calculationObjectId = res.data?.objectId ?? '';

        // Sync address in crm
        API.deal.syncCrmAddress(calculationObjectId);

        this.calculationObjectId = calculationObjectId;
      }
    } else {
      await API.parse.syncCalculationObject(this.addCheckboxListMenu(calculationObject, pages.basicData), this.calculationObjectId);

      API.deal.syncCrmAddress(this.calculationObjectId);
    }
  }

  /**
   * load water pump data to parse
   */
  async syncHotWaterParseData() {
    if (!this.calculationObjectId) {
      return;
    }

    const parseForm: any = {
      ...this.HotWaterForm,
      selectionHotWaterHeatPump: this.HotWaterForm.selectionHotWaterHeatPumpId,
    };

    delete parseForm.selectionHotWaterHeatPumpId;

    await API.parse.syncCalculationObject(this.addCheckboxListMenu(parseForm, pages.hotWaterPump), this.calculationObjectId);
  }

  /**
   * load storage extension data to parse
   */
  async syncStorageExtensionParseData() {
    if (!this.calculationObjectId) {
      return;
    }

    const calculationObject: any = {
      storageExtensionSelection: this.StorageExtensionForm.storageExtensionSelectionId,
    };

    await API.parse.syncCalculationObject(this.addCheckboxListMenu(calculationObject, pages.storageExtension), this.calculationObjectId);
  }

  /**
   * load blackout box data to parse
   */
  async syncBlackoutBoxParseData() {
    if (!this.calculationObjectId) {
      return;
    }

    await API.parse.syncCalculationObject(
      this.addCheckboxListMenu({ blackoutBoxSelection: this.BlackoutBoxSelectionId }, pages.blackoutBox),
      this.calculationObjectId,
    );
  }

  /**
   * load wallbox data to parse
   */
  async syncWallBoxParseData() {
    if (!this.calculationObjectId) {
      return;
    }

    const parseForm: any = {
      ...this.WallboxForm,
      wallboxSelection: this.WallboxForm.wallboxSelectionId,
    };

    delete parseForm.wallboxSelectionId;

    await API.parse.syncCalculationObject(this.addCheckboxListMenu(parseForm, pages.wallboxQoute), this.calculationObjectId);
  }

  /**
   * add checkboxes data to parse object
   */
  addCheckboxListMenu(object: any, lastPageUrl: string) {
    const checkboxListMenu = getCheckboxListMenuFromSwitchers(this.switchers);

    object.checkboxListMenu = checkboxListMenu;
    object.internalLastPageUrl = lastPageUrl;

    return object;
  }

  // set form switchers
  setFormSwitchers(formSwitchers: FormSwitcher[]) {
    this.switchers = formSwitchers;
  }

  setWallboxForm(newForm: WallBoxForm) {
    this.WallboxForm = newForm;
  }

  setPdfLoading(newValue: boolean) {
    this.IsPdfLoading = newValue;
  }

  setInternalObjectAddress(address: CrmAddress) {
    this.InternalObjectAddress = address;
  }

  setHotWaterForm(newForm: HotWaterHeatPumpQuoteWidget) {
    this.HotWaterForm = newForm;
  }

  setStoragesExtensionForm(newForm: StoragesExtensionForm) {
    this.StorageExtensionForm = newForm;
  }

  setMenuExpended(isExpended: boolean) {
    this.IsMenuExpended = isExpended;
  }

  /**
   * load blackout box data to parse
   */
  setProducts(calculationResult: ProductResultType | ProductResultErrorType) {
    runInAction(() => {
      const { pvProducts, hotWaterProducts, blackoutBoxProducts, memoryExtensionProducts, wallboxProducts, storageExtensionProducts } =
        calculationResult as ProductResultType;

      const allProducts = [
        ...(pvProducts?.data?.map((prod) => ({ ...prod, checkboxListMenu: 'photovoltaic' })) ?? []),
        ...(hotWaterProducts?.data?.map((prod) => ({ ...prod, checkboxListMenu: 'hotWaterHeatPump' })) ?? []),
        ...(blackoutBoxProducts?.data?.map((prod) => ({ ...prod, checkboxListMenu: 'blackoutBox' })) ?? []),
        ...(memoryExtensionProducts?.data?.map((prod) => ({ ...prod, checkboxListMenu: 'memoryExtension' })) ?? []),
        ...(wallboxProducts?.data?.map((prod) => ({ ...prod, checkboxListMenu: 'wallbox' })) ?? []),
        ...(storageExtensionProducts?.data?.map((prod) => ({ ...prod, checkboxListMenu: 'storageExtension' })) ?? []),
      ];

      const financingData = JSON.parse(JSON.stringify(calculationResult));

      Object.keys(financingData).forEach((key) => {
        const keyT = key as keyof typeof financingData;

        delete (financingData[keyT] as ProductResultType).data;
        delete (financingData[keyT] as ProductResultType).name;
      });

      delete (financingData as ProductResultType).pdfTemplateQoute;

      this.products = allProducts.reduce<{ [key in string]: { count: number } }>((acc, prod) => {
        acc[prod.serviceId!] = { ...omit(prod, 'serviceId'), count: prod.unit ?? 0 };

        return acc;
      }, {});

      // TODO remove all code that is related to loanCalculations in this file after testing financing
      // const {
      //   paymentSelection,
      //   leasingDownPayment,
      //   hirePurchaseDownPayment,
      //   financingDownPayment,
      //   hirePurchaseTerm,
      //   leasingTerm,
      //   financingTerm,
      // } = this.rootStore.financingStore.financingForm;
      // const { summaryPriceNet, leasingInterestRate, rentingInterestRate, financingInterestRate } = pvProducts || {};

      // let upfrontPayment: number = 0;
      // let interestRate: number = 0;
      // let runtime: number = 0;
      //
      // switch (paymentSelection) {
      //   case 'financing':
      //     upfrontPayment = financingDownPayment || 0;
      //     interestRate = financingInterestRate || 0;
      //     runtime = financingTerm || 0;
      //     break;
      //   case 'leasingSelfEmployed':
      //     upfrontPayment = leasingDownPayment || 0;
      //     interestRate = leasingInterestRate || 0;
      //     runtime = leasingTerm || 0;
      //     break;
      //   case 'rentingSelfEmployed':
      //     upfrontPayment = hirePurchaseDownPayment || 0;
      //     interestRate = rentingInterestRate || 0;
      //     runtime = hirePurchaseTerm || 0;
      //     break;
      //   default:
      //     break;
      // }
      //
      // if (interestRate && runtime) {
      //   switch (paymentSelection) {
      //     case 'financing':
      //       this.loanCalculations = Calculator.getFinancingCalculation(summaryPriceNet || 0, upfrontPayment, interestRate, runtime);
      //       break;
      //     case 'leasingSelfEmployed':
      //     case 'rentingSelfEmployed':
      //       this.loanCalculations = Calculator.getLoanCalculation(summaryPriceNet || 0, upfrontPayment, interestRate, runtime);
      //       break;
      //     default:
      //       this.loanCalculations = Calculator.getCashCalculation(summaryPriceNet || 0);
      //       break;
      //   }
      // } else {
      //   this.loanCalculations = Calculator.getCashCalculation(summaryPriceNet || 0);
      // }

      this.financingCalculations = financingData as FinancingCalculations;
    });
  }

  /**
   * set is create offer button available
   * @params value - new value
   */
  setIsGenerateQuote(value: boolean) {
    runInAction(() => {
      this.IsGenerateQuoteEnable = value;
    });
  }

  /**
   * save current form
   * @param urlForm - url of current form
   */
  async saveCurrentForm(urlForm: string) {
    if (urlForm === pages.basicData) {
      this.syncBasicParseData();
    }

    if (urlForm === pages.photovoltaikQuote) {
      await this.rootStore.photovoltaikStore.syncPhotovoltaikParseData();
    }

    if (urlForm === pages.hotWaterPump) {
      await this.syncHotWaterParseData();
    }

    if (urlForm === pages.wallboxQoute) {
      await this.syncWallBoxParseData();
    }

    if (urlForm === pages.memoryExtension) {
      await this.rootStore.memoryExtensionStore.syncMemoryExtensionData();
    }

    if (urlForm === pages.storageExtension) {
      await this.syncStorageExtensionParseData();
    }

    if (urlForm === pages.blackoutBox) {
      await this.syncBlackoutBoxParseData();
    }

    if (urlForm === pages.financing) {
      await this.rootStore.financingStore.syncFinancingParseData();
    }
  }

  /**
   * set blackout box selection id
   * @param newId - string
   */
  setBlackoutBoxSelectionId(newId: Nullable<string>) {
    this.BlackoutBoxSelectionId = newId;
  }

  /**
   * request pdf quote generation
   */
  async generatePdfQuote() {
    if (!this.calculationObjectId) {
      return null;
    }

    await this.syncParseProducts();
    const result = await API.sync.generatePdfQuote(this.calculationObjectId);
    const newQuoteNumber = result?.data?.quote?.quoteNumber as string;

    if (newQuoteNumber) {
      await API.parse.updateCalculationProperty('quoteNumber', newQuoteNumber, this.calculationObjectId);
    }

    return newQuoteNumber ?? null;
  }

  /**
   * fetch pdfs from parse
   * @param dealId - current crm deal id
   */
  async getPdfQuote(dealId?: string) {
    const quotes = await API.parse.getPdfQuotes(dealId ?? this.CrmDealId);

    if (quotes && Array.isArray(quotes)) {
      const firstQuote = quotes[0];

      runInAction(() => {
        this.PdfQuote = firstQuote;
      });
    }
  }

  // set other features to default if brand is different
  async setProductsBasedOnStorage(storageId: Nullable<string>) {
    const storageProduct = this.rootStore.cacheStore.getProductById(storageId ?? '');
    const memoryExtension = this.rootStore.cacheStore.getProductById(
      this.rootStore.memoryExtensionStore.memoryExtensionForm.memoryExtensionSelectionId ?? '',
    );
    const wallbox = this.rootStore.cacheStore.getProductById(this.wallboxForm.wallboxSelectionId ?? '');
    const blackoutBox = this.rootStore.cacheStore.getProductById(this.blackoutBoxSelectionId ?? '');

    if (memoryExtension && storageProduct) {
      const isSameBrand = memoryExtension.brandId === storageProduct?.brandId;

      if (!isSameBrand) {
        this.rootStore.memoryExtensionStore.setMemoryExtensionForm({
          memoryExtensionSelectionId: null,
          numberAdditionalBattery: 0,
          numberExistingBatteries: 0,
        });
      }
    }

    if (blackoutBox && storageProduct) {
      const isSameBrand = blackoutBox.brandId === storageProduct.brandId;

      if (!isSameBrand) {
        this.setBlackoutBoxSelectionId(null);
      }
    }

    if (wallbox && storageProduct) {
      const isSameBrand = wallbox.brandId === storageProduct.brandId;

      if (!isSameBrand) {
        this.setWallboxForm({ wallboxSelectionId: null });
      }
    }
  }

  /**
   * save all forms to parse and request for pdfs generation
   */
  async createQuote(pathname: string, recalculateDiscountPrecent?: () => void) {
    if (!this.isGenerateQuoteEnable || !this.isGenerateQuoteValidated) {
      return;
    }

    const currentUrl = pathname as keyof typeof pages;

    try {
      recalculateDiscountPrecent?.();
      this.setPdfLoading(true);

      await this.saveCurrentForm(currentUrl);
      const quoteNumber = await this.generatePdfQuote();

      if (quoteNumber) {
        this.rootStore.userStore.syncQuoteNumber(quoteNumber);
      }

      await this.getPdfQuote();
      this.setPdfLoading(false);
    } catch (error: any) {
      toastManager.showError(error.message);
      this.setPdfLoading(false);
    }
  }

  resetStore() {
    this.calculationObjectId = '';
    this.switchers = DataStore.defaultSwitchers;
    this.IsMenuExpended = true;
    this.WallboxForm = DataStore.defaultWallboxForm;
    this.BlackoutBoxSelectionId = null;
    this.HotWaterForm = DataStore.defaultWaterPumpForm;
    this.StorageExtensionForm = DataStore.defaultStorageExtensionForm;
    this.lastPageUrl = null;
    this.loanCalculations = { totalCost: 0, upfrontPayment: 0 };

    this.loadCalculationData();
  }
}

export default DataStore;
