/**
 * Created by Vladyslav Dubyna 01/29/24
 *
 * this is a calculator widget UI component
 */
import React, { ReactElement } from 'react';

export interface TDropdownData {
  dropdownTitle: string;
  dropdownContent: ReactElement;
  defaultOpen?: boolean;
}

export interface TWidgetSection {
  title: string;
  description?: string;
  dropdownData: Array<TDropdownData>;
}

export type WidgetData = Omit<TWidgetSection, 'dropdownData'> & {
  dropdownData: Array<Omit<TDropdownData, 'dropdownContent'> & { dropdownContentStr: Array<string> }>;
};

/**
 * render widget section properties
 * @param widgetData - WidgetData - widget data from library
 */
export const getWidgetDataSection = (pvWidgetdata: WidgetData | null) =>
  pvWidgetdata
    ? {
      ...pvWidgetdata,
      dropdownData: pvWidgetdata.dropdownData.map((dropdownDataItem: any) => ({
        ...dropdownDataItem,
        dropdownContent: (
          <div className="widget_dropdown_data">
            {(dropdownDataItem.dropdownContentStr as string[]).map((str, i, self) => {
              const strArr = str.split(':');

              if (strArr.length > 1) {
                return (
                  <div className="calculator-widget-row" key={`${str}-${i + self.length}`}>
                    <p>{strArr[0]}:</p>
                    <p className="calculator-widget-row-price">{strArr[1]}</p>
                  </div>
                );
              }
              if (strArr.length === 1 && !!strArr[0]) {
                return (
                  <div className="calculator-widget-row" key={`${str}-${i + self.length}`}>
                    <p>{strArr[0]}</p>
                  </div>
                );
              }

              return null;
            })}
          </div>
        ),
      })),
    }
    : null;
