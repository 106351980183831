/**
 * Created by Ilya Savinov 24/01/24
 *
 * this is a Checkbox component with multiple options
 *
 * props:
 * @options - CheckboxOption[] - options of the checkbox
 * @onChange - (options: CheckboxOption[]) => void - on option click handle
 */
import React from 'react';

import { IFormElementProps } from '../../interfaces/IComponents';

export interface CheckboxOption {
  id?: string | number;
  checked: boolean;
  value: string;
  disabled?: boolean;
  testId?: string;
}

interface CheckboxComponentProps extends IFormElementProps {
  options: CheckboxOption[];
  onChange?: (options: CheckboxOption[]) => void;
  isSingle?: boolean;
}

const CheckboxComponent: React.FC<CheckboxComponentProps> = ({ title, options, onChange, className, hint, isSingle }) => {
  const handleChange = (option: CheckboxOption) => () => {
    const newOptions = options.map((o) => {
      const isEnableAction = !option.checked;

      if (o.value === option.value) {
        return {
          ...o,
          checked: !o.checked,
        };
      }

      return {
        ...o,
        checked: isEnableAction && isSingle ? false : o.checked,
      };
    });

    onChange?.(newOptions);
  };

  return (
    <div className={`checkbox-container ${className ?? ''}`}>
      <div className="checkbox-title">{title}</div>
      <div className="checkbox-group">
        {options.map((option) => (
          <label className="checkbox-label" key={option.value}>
            <input
              type="checkbox"
              className="checkbox-input"
              checked={option.checked}
              value={option.value}
              disabled={option.disabled}
              onChange={handleChange(option)}
              data-testid={option.testId}
            />
            {option.value}
          </label>
        ))}
      </div>
      {hint && <div className="hint">{hint}</div>}
    </div>
  );
};

export default CheckboxComponent;
