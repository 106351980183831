/**

 * Created by Ilya Savinov 18/03/24

 *

 * this class controlls notifications
 */
import { toast, ToastOptions } from 'react-toastify';
import React from 'react';

import ErrorMessage from '../components/popups/ErrorMessage';

const errorOptions: ToastOptions = {
  autoClose: 3000,
  hideProgressBar: true,
};

// const successOptions: ToastOptions = {
//   hideProgressBar: true,
//   autoClose: 3000,
// };

// const infoOptions: ToastOptions = {
//   hideProgressBar: true,
//   autoClose: 10000,
// };

export class ToastManager {
  showError(message: string, options?: ToastOptions, onClick?: () => void) {
    toast(<ErrorMessage title={message} onClick={onClick} />, {
      ...errorOptions,
      ...options,
    });
  }

  // showInfoMessage(message: string, subtitle?: string, options?: ToastOptions) {
  //     toast(<InfoMessage text={message} subtitle={subtitle} />, { ...options, ...infoOptions });
  // }

  // showSuccessfulMessage(title: string, options?: ToastOptions) {
  //     toast(<SuccessMessage title={title} />, { ...successOptions, ...options });
  // }
}

export const toastManager = new ToastManager();
