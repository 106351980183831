/**
 * Created by Ilya Savinov 19/01/24
 *
 * this is a Toogle switch component with boolean state
 *
 * props:
 * @label - string - google api key
 * @checked - boolean? - switch is turned on/off
 * @onClick - () => void - handle click event on switch
 */
import React from 'react';

import { IFormElementProps } from '../../interfaces/IComponents';
import { removeAllSpaces } from '../../lib/PageUtils';

export interface IToggleSwitchProps extends IFormElementProps {
  label: string;
  checked?: boolean;
  onClick?: () => void;
  collapsed?: boolean;
}

const ToggleSwitch: React.FC<IToggleSwitchProps> = ({ label, checked, onClick, isDisabled, onMouseEnter, onMouseLeave, collapsed }) => (
  <div
    className={`toggle-switch ${isDisabled && 'disabled'} ${collapsed ? 'collapsed' : ''}`}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={() => !isDisabled && onClick?.()}
  >
    <div className="switch" onClick={() => !isDisabled && onClick?.()} data-testid={`${removeAllSpaces(label)}-switch`}>
      <input type="checkbox" checked={checked} id="togBtn" disabled />
      <div className="slider round" />
    </div>
    <span className={`toggle-label ${collapsed ? 'collapsed' : ''}`}>{label}</span>
  </div>
);

export default ToggleSwitch;
