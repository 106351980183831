/**
 * Created by Vladyslav Dubyna 04/03/24
 *
 * this is Hot water pump page
 */

import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import ContentArea from '../components/theme/ContentArea';
import Button from '../components/buttons/Button';
import Bottom from '../components/theme/Bottom';
import i18n from '../i18n';
import CheckboxComponent, { CheckboxOption } from '../components/forms/Checkbox';
import useStore from '../hooks/useStore';
import hotWaterPumpLogo from '../assets/images/hotWaterPump.jpg';
import Select from '../components/forms/Select';
import { SelectOption } from '../interfaces/IComponents';
import { useBSHNavigation } from '../hooks/useBSHNavigation';
import Tooltip from '../components/elements/Tooltip';
import Validation from '../lib/Validation';
import { InputRefType } from '../components/forms/InputText';

const HOT_WATER_PUMP_SERVICE_ID = 'hotWaterHeatPump';

const HotWaterPumpPage: React.FC = observer(() => {
  const { dataStore, cacheStore } = useStore();
  const { goToNextPage, goToPreviousPage } = useBSHNavigation();
  const [nextButtonTooltip, setNextButtonTooltip] = useState(false);
  const hotWaterPumpRef = useRef<InputRefType>(null);

  // getting proper options
  const hotWaterOptions =
    cacheStore.getProductsByGroupId(HOT_WATER_PUMP_SERVICE_ID)?.map((product) => ({
      id: product.serviceId,
      label: product.name,
    })) ?? [];

  const { selectionHotWaterHeatPumpId, centralHeatingExist } = dataStore.hotWaterForm;

  useEffect(() => {
    const refValue = hotWaterPumpRef.current;

    if (refValue && centralHeatingExist) {
      const validObj = refValue.inputValidate(selectionHotWaterHeatPumpId ?? '');

      if (!validObj.validated) {
        refValue.setValidation(validObj);
      }
    }
  }, [centralHeatingExist]);

  // checkbox handle method
  const handleCheckboxChangeByName = (name: keyof typeof dataStore.hotWaterForm) => (newOptions: CheckboxOption[]) => {
    const valueByName = newOptions.find((newOption) => newOption.id === name);

    const formToSet = {
      ...dataStore.hotWaterForm,
      [name]: valueByName?.checked,
    };

    if (!valueByName?.checked) {
      formToSet.selectionHotWaterHeatPumpId = null;
    }
    dataStore.setHotWaterForm(formToSet);
  };

  // seelect handle method
  const handleSelectChangeByName = (name: keyof typeof dataStore.hotWaterForm) => (value: SelectOption) => {
    const formToSet = { ...dataStore.hotWaterForm, [name]: value.id };

    dataStore.setHotWaterForm(formToSet);
  };

  const selectionHotWaterHeatPump = cacheStore.getProductById(selectionHotWaterHeatPumpId ?? '', HOT_WATER_PUMP_SERVICE_ID);
  const emptyValidateFunction = (p: string) => Validation.emptyValue(p, i18n.t('validations.fieldRequired'));

  const options = [
    { id: '', label: i18n.t('photovoltaikPage.noSelection') },
    ...hotWaterOptions.sort((a, b) => a.label.localeCompare(b.label)),
  ];

  const isValidated = !centralHeatingExist || selectionHotWaterHeatPump;

  return (
    <ContentArea>
      <div className="content-main">
        <div className="wallbox-title-row">
          <img src={hotWaterPumpLogo} className="title-image" alt="photovoltaik" />
          <h2 className="wallbox-title">{i18n.t('forms.hotWaterPump')}</h2>
        </div>
        <div className="hot-water-pump-column">
          <CheckboxComponent
            options={[
              {
                id: 'centralHeatingExist',
                checked: centralHeatingExist || false,
                value: i18n.t('hotWaterPumpPage.centralHeatingExist'),
                testId: 'centralHeatingExist',
              },
            ]}
            onChange={handleCheckboxChangeByName('centralHeatingExist')}
            isSingle
          />
          {centralHeatingExist && (
            <Select
              ref={hotWaterPumpRef}
              title={i18n.t('hotWaterPumpPage.select')}
              value={
                selectionHotWaterHeatPump
                  ? {
                    id: selectionHotWaterHeatPump?.id,
                    label: selectionHotWaterHeatPump?.name,
                  }
                  : undefined
              }
              options={options}
              onSuggestionSelected={handleSelectChangeByName('selectionHotWaterHeatPumpId')}
              testId="hot-waterPump-select"
              validateFunc={emptyValidateFunction}
              placeholder={i18n.t('photovoltaikPage.noSelection')}
            />
          )}
        </div>
      </div>
      <Bottom>
        <Button role="btnCancel" text={i18n.t('buttons.back')} onClick={goToPreviousPage} />
        <Tooltip
          isShow={nextButtonTooltip && centralHeatingExist && !selectionHotWaterHeatPump}
          text={i18n.t('validations.validateNextButton')}
          position="top"
          bottomButtons
        >
          <Button
            role="btnNext"
            text={i18n.t('buttons.next')}
            onClick={() => {
              dataStore.syncHotWaterParseData();
              goToNextPage();
            }}
            isGreen
            isDisabled={!isValidated}
            leftMargin20
            testId="next-button"
            onMouseEnter={() => setNextButtonTooltip(true)}
            onMouseLeave={() => setNextButtonTooltip(false)}
          />
        </Tooltip>
      </Bottom>
    </ContentArea>
  );
});

export default HotWaterPumpPage;
