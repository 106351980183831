/**
 * Created by Ilay Savinov 26/01/24
 *
 * this is a Tooltip component
 *
 * props:
 * @setStreetName - (streetName: string) => void - set street name
 * @setCity - (city: string) => void - set street name
 * @setStreetNumber - (streetNumber: string) => void - set street number
 * @setPostalCode - (postalCode: string) => void - set postal code
 * @validateMsg - string? - error message
 */
import React, { useRef, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

import { IInputTextProps } from '../../interfaces/IComponents';
import GoogleMapsAutocomplete, { GooglePlaceDetails } from '../../services/GoogleMapsAutocomplete';

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
  version: 'weekly',
});

interface AddressInputProps extends IInputTextProps {
  onStreetSelected?: (details: GooglePlaceDetails) => void;
  validateMsg?: string;
}

const AddressInput: React.FC<AddressInputProps> = (props) => {
  const {
    defaultValue,
    value,
    className,
    title,
    unit,
    readOnly,
    type,
    placeholder,
    onChange,
    onKeyDown,
    isFullWidth,
    suffix,
    min,
    max,
    hint,
    onStreetSelected,
    validateMsg,
    testId,
    isDisabled,
  } = props;
  // state variables
  const autoCompleteService = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const options = {
    componentRestrictions: { country: 'de' },
    fields: ['address_components'],
    types: ['route', 'street_number', 'locality', 'postal_code'],
  };

  // load google Places API library and connect to address input
  const loadMaps = async () => {
    const { Autocomplete } = (await loader.importLibrary('places')) as google.maps.PlacesLibrary;

    autoCompleteService.current = new Autocomplete(inputRef.current, options);

    autoCompleteService?.current?.addListener('place_changed', async () => {
      const place = await autoCompleteService.current.getPlace();

      const addressComponents = place.address_components as google.maps.places.AddressComponent[];

      const details = GoogleMapsAutocomplete.extractAddressComponents(addressComponents);

      onStreetSelected?.(details);
    });
  };

  useEffect(() => {
    loadMaps();
  }, []);

  // on change handler
  const onInputChange = (event: { target: { value: string } }) => {
    const { value: newText } = event.target;

    onChange?.(newText);
  };

  let inputClassName = 'input-text';

  if (className) {
    inputClassName += ` ${className}`;
  }

  if (isFullWidth) {
    inputClassName += ' full-width';
  }

  let titleClass = 'title';

  if (validateMsg) {
    titleClass += ' red';
  }

  return (
    <div className={inputClassName}>
      {!!title && <div className={titleClass}>{title}</div>}
      <div className={`inner ${isDisabled ? 'disabled' : ''}`}>
        <div className="inner-input">
          {!readOnly ? (
            <input
              ref={inputRef}
              type={type || 'text'}
              placeholder={placeholder || ''}
              value={value || ''}
              defaultValue={defaultValue}
              onChange={onInputChange}
              onKeyDown={onKeyDown}
              min={min}
              max={max}
              data-testid={testId}
              disabled={isDisabled}
            />
          ) : (
            <div className="text">{value}</div>
          )}
          {suffix && <div className="suffix">{suffix}</div>}
        </div>
        {!!unit && <div className="unit">{unit}</div>}
      </div>
      {validateMsg && (
        <div className="error" data-testid={`${testId}-error`}>
          {validateMsg}
        </div>
      )}
      {hint && !validateMsg && <div className="hint">{hint}</div>}
    </div>
  );
};

export default AddressInput;
