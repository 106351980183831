import pages from '../const/pages';
import { FormSwitcher } from '../interfaces/ICommon';

export const getCheckboxListMenuFromSwitchers = (switchers: FormSwitcher[]) => {
  const isPhotovoltaic = switchers.find((form) => form.url === pages.photovoltaikQuote)?.checked ?? false;
  const isWaterPump = switchers.find((form) => form.url === pages.hotWaterPump)?.checked ?? false;

  const checkboxListMenu = {
    photovoltaic: isPhotovoltaic,
    hotWaterHeatPump: isWaterPump,
    wallbox: switchers.find((form) => form.url === pages.wallboxQoute)?.checked ?? false,
    blackoutBox: switchers.find((form) => form.url === pages.blackoutBox)?.checked ?? false,
    storageExtension: switchers.find((form) => form.url === pages.storageExtension)?.checked ?? false,
    memoryExtension: switchers.find((form) => form.url === pages.memoryExtension)?.checked ?? false,
    financing: isPhotovoltaic || isWaterPump,
  };

  return checkboxListMenu;
};

export const getSwitchersFromCheckboxMenu = (switchers: FormSwitcher[], checkboxListMenu: any) =>
  switchers.map((form) => {
    if (form.url === pages.photovoltaikQuote) {
      return {
        ...form,
        checked: checkboxListMenu.photovoltaic ?? true,
      };
    }

    if (form.url === pages.hotWaterPump) {
      return {
        ...form,
        checked: checkboxListMenu.hotWaterHeatPump ?? false,
      };
    }

    if (form.url === pages.wallboxQoute) {
      return {
        ...form,
        checked: checkboxListMenu.wallbox ?? false,
      };
    }

    if (form.url === pages.memoryExtension) {
      return {
        ...form,
        checked: checkboxListMenu.memoryExtension ?? false,
      };
    }

    if (form.url === pages.blackoutBox) {
      return {
        ...form,
        checked: checkboxListMenu.blackoutBox ?? false,
      };
    }

    if (form.url === pages.storageExtension) {
      return {
        ...form,
        checked: checkboxListMenu.storageExtension ?? false,
      };
    }

    if (form.url === pages.financing) {
      return {
        ...form,
        checked: checkboxListMenu.financing ?? false,
      };
    }

    return form;
  });
