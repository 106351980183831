/**
 * Created by Ilya Savinov 03/18/24
 *
 * this is progress indicator component
 * @param percentage - number - loading percentage
 * @param label - string - loading label
 */
import React from 'react';

import { IProgressIndicatorProps } from '../../interfaces/IComponents';

function ProgressIndicator({ percentage, label }: IProgressIndicatorProps) {
  return (
    <div className="progress-indicator">
      <div className="progress-header">
        {label} <span className="progress-percentage">{percentage} %</span>
      </div>
      <div className="progress-bar">
        <div className="progress-bar-fill" role="progress-bar-fill" style={{ width: `${percentage}%` }} />
      </div>
    </div>
  );
}

export default ProgressIndicator;
