/**
 * Created by Max Gornostayev 31/12/23
 *
 * This is a library for logging data
 */

/* eslint-disable no-console */

class Logger {
  static instance: Logger;

  static getInstance() {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }

    return Logger.instance;
  }

  DEBUG: boolean;

  WARNING: boolean;

  ERROR: boolean;

  private constructor() {
    this.DEBUG = process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_ENV !== 'beta';
    this.WARNING = true;
    this.ERROR = true;
  }

  debug(obj: unknown, label?: string) {
    if (!this.DEBUG) return;
    console.log(label || 'DEBUG', obj);
  }

  warn(msg: string) {
    if (this.WARNING) console.warn(msg);
  }

  error(msg: any, label: string = 'ERROR:') {
    if (this.ERROR) {
      console.error(label, msg);
    }
  }
}

const loggerInstance = Logger.getInstance();

export default loggerInstance;
