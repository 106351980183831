/**
 * Created by Max Gornostayev on 01/15/24
 *
 * this is main import file
 */
import sync from './v1/sync';
import deal from './v1/deal';
import parse from './v1/parse';

class API {
  static sync = sync;

  static deal = deal;

  static parse = parse;
}

export default API;
