/**
 * Created by Vladyslav Dubyna 04/03/24
 *
 * this is the file for common utils for pages
 */
export const removeAllSpaces = (str: string) => str.replace(/\s/g, '');

export const toFloatNumber = (num: number, fractionDigits = 2) => Number(parseFloat(num.toString()).toFixed(fractionDigits));

export const toFloatString = (num: number, fractionDigits = 2) => parseFloat(num.toString()).toFixed(fractionDigits);
