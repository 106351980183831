/**
 * Created by Ilya Savinov 29/01/24
 *
 * this is root store
 */
import CacheStore from './CacheStore';
import DataStore from './DataStore';
import FinancingStore from './FinancingStore';
import MemoryExtensionStore from './MemoryExtensionStore';
import PhotovoltaikStore from './PhotovoltaikStore';
import UserStore from './UserStore';

class RootStore {
  userStore: UserStore;

  cacheStore: CacheStore;

  dataStore: DataStore;

  photovoltaikStore: PhotovoltaikStore;

  financingStore: FinancingStore;

  memoryExtensionStore: MemoryExtensionStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.cacheStore = new CacheStore(this);
    this.dataStore = new DataStore(this);
    this.photovoltaikStore = new PhotovoltaikStore(this);
    this.financingStore = new FinancingStore(this);
    this.memoryExtensionStore = new MemoryExtensionStore(this);
  }
}

export default RootStore;
