/**
 * Created by Ilya Savinov 30/01/24
 *
 * this is Select component with dropdown
 *
 * props:
 * @options - SelectOption[] - options for dropdown
 * @onSuggestionSelected - (value: string) => void - on option click handle
 * @wrapperClassName - string - styles for wrapper
 */
import React, { useState, useRef, useEffect, forwardRef, useMemo, RefObject } from 'react';

import InputText, { InputRefType } from './InputText';
import { SelectOption } from '../../interfaces/IComponents';
import arrowIcon from '../../assets/icons/arrow.svg';
import { IValidateResult } from '../../interfaces/ICommon';

interface SelectProps {
  title?: string;
  options: SelectOption[] | [];
  onSuggestionSelected?: (value: SelectOption) => void;
  wrapperClassName?: string;
  value?: Nullable<SelectOption>;
  isFullWidth?: boolean;
  placeholder?: string;
  validateFunc?: (p: string) => IValidateResult;
  testId?: string;
}

const Select = forwardRef<InputRefType, SelectProps>((props, inputRef) => {
  const { options, value, onSuggestionSelected, wrapperClassName, isFullWidth, title, placeholder, validateFunc, testId } = props;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

  // handle suggestion click, set dropdown false and call onSuggestionSelected
  const handleSuggestionClick = (suggestion: SelectOption) => {
    setIsDropdownVisible(false);
    onSuggestionSelected?.(suggestion);

    if (validateFunc) {
      const { msg, validated } = validateFunc(suggestion.label);

      const ref = inputRef as RefObject<InputRefType>;

      ref.current?.setValidation({ msg, validated });
    }
  };

  // handle click outside to close dropdown
  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setIsDropdownVisible(false);
    }
  };

  // add outside click listeners
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const className = useMemo(() => {
    let classNameWrapper = 'select';

    if (isFullWidth) {
      classNameWrapper += ' full-width';
    }

    if (wrapperClassName) {
      classNameWrapper += ` ${wrapperClassName}`;
    }

    return classNameWrapper;
  }, [wrapperClassName, isFullWidth]);

  return (
    <div className={className} ref={wrapperRef}>
      <div className="select-input-wrapper" onClick={toggleDropdown}>
        <InputText
          role="select-input"
          ref={inputRef}
          value={value?.label || placeholder}
          isFullWidth
          title={title}
          readOnly
          leftIcon={value?.imageUrl}
          validateFunc={validateFunc}
          rightIcon={arrowIcon}
          testId={testId}
        />
      </div>
      {isDropdownVisible && !!options.length && (
        <div className="select-dropdown" data-testid={`${testId}-dropdown`}>
          {options.map((option) => (
            <div
              key={option.id ?? option.label}
              onClick={() => handleSuggestionClick(option)}
              className={`select-item ${value?.label === option.label ? 'active' : ''}`}
            >
              {option.imageUrl && (
                <div className="option-image-wrapper">
                  <img src={option.imageUrl} alt="option" className="option-image" />
                </div>
              )}
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

Select.displayName = 'Select';

export default Select;
