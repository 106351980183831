/**
 * Created by Max Gornostayev 12/26/21
 *
 * this is configuration file with public information
 */

import { getConfigs } from 'bsh-calculator';

import i18n from '../i18n';
import pageUrl from '../const/pages';

const frontendConfig = {
  api: {
    timeoutFunc: 30000,
    timeoutBitrix: 30000,
  },

  storesExpiration: {
    user: 4320000, // 12 hours
    contracts: 360000, // 1 hour
    data: 4320000, // 12 hours
  },
};

const config = { ...getConfigs(pageUrl, i18n.t), ...frontendConfig };

export const salutationFormOptions = [
  { id: 'inPersonal', label: i18n.t('common.inPersonal') },
  { id: 'personal', label: i18n.t('common.personal') },
];

export const ALLOW_DEPARTMENTS_PRODUCTS_RESET = [320];

export default config;
