/**
 * Created by Max Gornostayev 01/15/24
 *
 * this is a right panel
 */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { IGeneralProps } from '../../interfaces/IComponents';
import BreadCrumbsMenu from '../elements/BreadCrumbsMenu';
import useStore from '../../hooks/useStore';

const ContentArea = observer(({ children }: IGeneralProps) => {
  const location = useLocation();
  const { dataStore } = useStore();
  const { crumbs } = dataStore;

  const currentLocationKey = Object.keys(crumbs).find((key) => location.pathname.includes(key));

  return (
    <div className="content-inner" role="contentinfo">
      <BreadCrumbsMenu locations={crumbs} currentLocation={currentLocationKey ?? ''} />
      <div className="page-content">{children}</div>
    </div>
  );
});

export default ContentArea;
