/**
 * Created by Max Gornostayev 12/22/24
 *
 * this is a button component
 *
 * props:
 * @onClick - func - function that handler onClick
 * @isFullWidth - bool - is button use full width of the parent
 * @className - string - custom class name
 * @height - int - custom height of the button
 * @isGreen - source - is green button
 * @isTransparent - source - is transparent button
 * @role - string - id for testing
 * @text - string - title
 */
import React, { useMemo } from 'react';

import { IButtonProps } from '../../interfaces/IComponents';

export default function Button(props: IButtonProps) {
  const {
    onClick,
    onMouseEnter,
    onMouseLeave,
    isGreen,
    isFullWidth,
    isTransparent,
    height,
    width,
    role,
    text,
    leftMargin20,
    isDisabled,
    className: customClassName,
    testId,
    icon,
    showIcon,
    collapsed,
  } = props;

  const funcOnClick = () => {
    if (isDisabled) {
      return;
    }
    onClick?.();
  };

  const { className, style } = useMemo(() => {
    let classes = 'button';
    const styles: { [index: string]: string } = {};

    if (isGreen) {
      classes += ' green-btn';
    }

    if (isTransparent) {
      classes += ' transparent-btn';
    }
    if (isFullWidth) {
      classes += ' full-width';
    }
    if (leftMargin20) {
      classes += ' left-margin-20';
    }

    if (isDisabled) {
      classes += ' disabled';
    }

    // eslint-disable-next-line react/destructuring-assignment
    if (customClassName) {
      // eslint-disable-next-line react/destructuring-assignment
      classes += ` ${customClassName}`;
    }
    if (height) {
      style.height = `${height}px`;
    }

    if (width) {
      style.width = `${width}px`;
    }

    return { className: classes, style: styles };
  }, [isGreen, isTransparent, isFullWidth, leftMargin20, isDisabled, customClassName, height, width]);

  return (
    <div
      className={className}
      style={style}
      onClick={funcOnClick}
      role={role}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={testId}
    >
      <div className="inner">
        {icon && <img src={icon} alt="asd" className={`icon ${showIcon ? 'show-icon' : ''} ${collapsed ? 'collapsed' : ''}`} />}
        <div className={`text ${collapsed ? 'collapsed' : ''}`}>{text}</div>
      </div>
    </div>
  );
}
