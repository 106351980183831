/**
 * Created by Ilya Savinov 24/01/24
 *
 * this is a Tooltip component
 *
 * props:
 * @label - string - slider label
 * @value - number? - value of slider
 * @onChange - (value: number) => void - on slider position change
 * @inputSuffix - string? - input suffix
 * @step - number? - step for slider
 */
import React from 'react';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

import { IFormElementProps } from '../../interfaces/IComponents';
import InputText from './InputText';
import scssVariables from '../../styles/_variables.scss';

export interface SliderProps extends IFormElementProps {
  label?: string;
  value?: number;
  onChange?: (value: number) => void;
  inputSuffix?: string;
  step?: number;
  inputTestId?: string;
  testId?: string;
  marks?: { [key in number]: string | React.ReactNode };
}

const CustomSlider: React.FC<SliderProps> = ({
  min,
  max,
  value,
  marks,
  label,
  onChange,
  className,
  inputSuffix,
  step,
  inputTestId,
  testId,
}) => {
  const sliderStyles = {
    track: { background: scssVariables.colorGreen, height: '7px' },
    rail: { height: '7px' },
    handle: {
      background: scssVariables.colorGreen,
      opacity: 1,
      border: 0,
      boxShadow: 'initial',
      width: '25px',
      height: '25px',
      borderRadius: '25px',
      marginTop: '-9px',
    },
  };

  /**
   * handle input blur with validation
   */
  const handleInputBlur = () => {
    if (min && value && value < min) {
      onChange?.(min);
    }

    if (max && value && value < max) {
      onChange?.(max);
    }

    if (max && min && step && value) {
      const steps = new Array((max - min) / step + 1).fill(0).map((_, index) => min + step * index);
      const smallestDiff = steps
        .map((s) => Math.abs(value - s))
        .reduce(
          (acc, diff, i) => {
            if (i === 0 || diff < acc.difference || diff === 0) {
              return { difference: diff, index: i };
            }

            return acc;
          },
          { difference: 0, index: 0 },
        );

      const newValue = steps[smallestDiff.index];

      onChange?.(newValue);
    }
  };

  /**
   * handle change slider and input value
   * @param newValue - number - new slider value
   */
  const handleOnChange = (newValue: number) => {
    onChange?.(newValue);
  };

  return (
    <div className={`slider-container ${className ?? ''}`} role="custom-slider">
      <span className="slider-label">{label}</span>
      <div className="slider-row" data-testid={testId}>
        <Slider
          value={value}
          marks={marks}
          min={min}
          max={max}
          step={marks ? null : step}
          styles={sliderStyles}
          dotStyle={{ display: 'none' }}
          className="slider"
          onChange={(v) => handleOnChange(v as number)}
        />
        <InputText
          value={value?.toString() ?? '0'}
          type="number"
          role="textbox"
          step={0.5}
          min={min}
          max={max}
          onChange={(newValue) => handleOnChange(newValue as number)}
          className="slider-input"
          suffix={inputSuffix}
          onBlur={handleInputBlur}
          testId={inputTestId}
          noBottom
        />
      </div>
    </div>
  );
};

export default CustomSlider;
