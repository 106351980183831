/**
 * Created by Ilya Savinov 13/02/24
 *

 * hook to get store
 *
 */
import { MobXProviderContext } from 'mobx-react';
import { useContext } from 'react';

import RootStore from '../stores/RootStore';

function useStore() {
  return useContext(MobXProviderContext) as RootStore;
}

export default useStore;
