/**
 * Created by Max Gornostayev 02/15/23
 *
 * this is a component that shows an input text element
 */

import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';

import { IInputTextProps } from '../../interfaces/IComponents';
import { IValidateResult } from '../../interfaces/ICommon';

export type InputRefType = {
  inputValidate: (value: string) => IValidateResult;
  setValidation: (validObj: IValidateResult) => void;
};

const InputText = forwardRef<InputRefType, IInputTextProps>((props, ref) => {
  // props
  const {
    defaultValue,
    value,
    className,
    title,
    unit,
    validateFunc,
    readOnly,
    role,
    type,
    placeholder,
    onChange,
    isError,
    onKeyDown,
    isFullWidth,
    suffix,
    min,
    max,
    hint,
    isDisabled,
    leftIcon,
    onMouseEnter,
    onMouseLeave,
    step,
    onBlur,
    testId,
    rightIcon,
    hintOnFocus,
    noBottom,
  } = props;
  // state variables
  const [inputValidateMsg, setValidateMsg] = useState('');
  const [isInputValid, setIsValid] = useState(true);
  const [hintFocusState, setHintFocusState] = useState(false);

  // do validation
  const inputValidate = (checkValue = ''): IValidateResult => {
    if (validateFunc) {
      const validObj = validateFunc(checkValue as string) as IValidateResult;

      return validObj;
    }

    return { validated: true, msg: '' };
  };

  useEffect(() => {
    const { validated, msg } = inputValidate((value as string) ?? '');

    setValidateMsg(msg);
    setIsValid(validated);
  }, []);

  // set validation msg
  const setValidation = (obj: IValidateResult) => {
    setValidateMsg(obj.msg);
    setIsValid(obj.validated);
  };

  // Expose the function to the parent using useImperativeHandle
  useImperativeHandle(ref, () => ({ inputValidate, setValidation }));

  // on change handler
  const onInputChange = (event: { target: { value: string } }) => {
    const { value: newText } = event.target;

    const { validated, msg } = inputValidate(newText);

    setValidateMsg(msg);
    setIsValid(validated);

    onChange?.(newText);
  };

  let inputClassName = 'input-text';

  if (className) {
    inputClassName += ` ${className}`;
  }

  if (isFullWidth) {
    inputClassName += ' full-width';
  }

  const isNotValid = !!validateFunc && !isInputValid;

  let titleClass = 'title';

  if (isNotValid) {
    titleClass += ' red';
  }

  const isErrorVisible = isNotValid || isError;

  const handleBlur = () => {
    if (min || max) {
      if (min && Number(value) < min) {
        onChange?.(min);
      }

      if (max && Number(value) > max) {
        onChange?.(max);
      }
    }
    setHintFocusState(false);
    onBlur?.();
  };

  return (
    <div className={inputClassName}>
      {!!title && <div className={titleClass}>{title}</div>}
      <div className={`inner ${isDisabled ? 'disabled' : ''}`}>
        <div className="inner-input" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {leftIcon && (
            <div className="left-icon-wrapper">
              <img src={leftIcon} alt="option" className="left-icon" />
            </div>
          )}
          {!readOnly ? (
            <input
              role={role || 'textInputTestId'}
              type={type || 'text'}
              placeholder={placeholder || undefined}
              value={value ?? ''}
              defaultValue={defaultValue}
              onChange={onInputChange}
              onKeyDown={onKeyDown}
              min={min}
              step={step}
              max={max}
              disabled={isDisabled}
              onBlur={handleBlur}
              onFocus={() => setHintFocusState(true)}
              data-testid={testId}
            />
          ) : (
            <div className="text" data-testid={testId}>
              {value}
            </div>
          )}
          {rightIcon && <img src={rightIcon} alt="icon" className="right-icon" />}
          {suffix && <div className="suffix">{suffix}</div>}
        </div>
        {!!unit && <div className="unit">{unit}</div>}
      </div>
      {!noBottom && (
        <div className="input-bottom">
          {isErrorVisible && !(hintFocusState && hintOnFocus) && (
            <div className="error" data-testid={`${testId}-error`}>
              {inputValidateMsg}
            </div>
          )}
          {!hintFocusState && hint && !isErrorVisible && <div className="hint">{hint}</div>}
          {!!hintOnFocus && hintFocusState && <div className="hint">{hintOnFocus}</div>}
        </div>
      )}
    </div>
  );
});

InputText.displayName = 'InputText';

export default InputText;
