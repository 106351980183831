/**
 * Created by Max Gornostayev 12/15/23
 *
 * this is a component that show loading animation
 */

import React from 'react';
import ReactLoading from 'react-loading';

import { IGeneralProps } from '../../interfaces/IComponents';

export default function Loading(props: IGeneralProps) {
  const { isShow } = props;

  if (!isShow) {
    return null;
  }

  return (
    <div className="loading">
      <ReactLoading type="spin" color="#234557" />
    </div>
  );
}
