const countries = {
  deutschland: {
    code: '0049',
    title: '+ 49 (Germany)',
    titleNumber: '+49',
    label: 'Germany',
    id: 'de',
  },
  switzerland: {
    code: '0041',
    title: '+ 41 (Switzerland)',
    titleNumber: '+41',
    label: 'Switzerland',
    id: 'ch',
  },
  liechtenstein: {
    code: '0097',
    title: '+ 971 (Liechtenstein)',
    titleNumber: '+971',
    label: 'Liechtenstein',
    id: 'li',
  },
  austrian: {
    code: '0043',
    title: '+ 43 (Austria)',
    titleNumber: '+43',
    label: 'Austria',
    id: 'at',
  },
  italian: {
    code: '0099',
    title: '+ 99 (Italy)',
    titleNumber: '+99',
    label: 'Italy',
    id: 'it',
  },
};

export default countries;
