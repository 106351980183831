/**
 * Created by Vladyslav Dubyna 29/02/24
 *
 * this is the main layout that will be used for forms
 */

import React, { FC } from 'react';

import LeftArea from '../components/theme/Left';
import RightArea from '../components/theme/Right';
import CalculatorWidget from '../components/calculator-widget/calculator-widget';
import i18n from '../i18n';

type FormLayoutProps = {
  children: React.ReactNode;
};

const FormLayout: FC<FormLayoutProps> = ({ children }) => (
  <div className="app missing-data-page">
    <div className="content">
      <LeftArea />
      <div className="without-menu">
        {children}
        <RightArea>
          <CalculatorWidget />
        </RightArea>
      </div>
    </div>
    <div className="responsive-error">
      <span className="responsive-error-title">{i18n.t('header.title')}</span>
      <span className="responsive-error-subtitle">{i18n.t('errors.responsiveUIError')}</span>
    </div>
  </div>
);

export default FormLayout;
