/**
 * Created by Max Gornostayev 01/14/24
 *
 * Help library
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import deTranslatiton from './lang/de.json';
import enTranslatiton from './lang/en.json';

i18n.use(initReactI18next).init({
  lng: process.env.REACT_APP_LANG, // if you're using a language detector, do not define the lng option
  debug: process.env.REACT_APP_ENV === 'dev',
  resources: {
    en: { translation: enTranslatiton },
    de: { translation: deTranslatiton },
  },
});

export default i18n;
