const pages = {
  root: '/',
  install: '/install',
  page404: '/404',
  missingRequiredData: '/missingRequiredData',
  basicData: '/basicData',
  photovoltaikQuote: '/photovoltaikQuote',
  wallboxQoute: '/wallboxQoute',
  blackoutBox: '/blackoutBox',
  hotWaterPump: '/hotWaterPump',
  memoryExtension: '/memoryExtension',
  storageExtension: '/storageExtension',
  financing: '/financing',
};

export default pages;
