/**
 * Created by Max Gornostayev 12/28/23
 *
 * Validation logic
 *
 */

import validator from 'email-validator';

import i18n from '../i18n';
import config from '../config';
import countries from '../const/countries';
import Utils from './Utils';
import DateUtil from './DateUtil';
import { IValidateResult } from '../interfaces/ICommon';

const validationReturnValue = (validated: boolean, msg: string = ''): IValidateResult => ({ validated, msg });

const Validation = {
  job: (val: string): IValidateResult => {
    const patt = /^[a-zA-Z ]{2,}$/g;

    return validationReturnValue(patt.test(val), i18n.t('validations.validationJob'));
  },

  nationality: (val: string): IValidateResult => {
    const patt = /^[a-zA-Z ]{2,}$/g;

    return validationReturnValue(patt.test(val), i18n.t('validations.validationNationality'));
  },

  birthdate: (val: string): IValidateResult => {
    if (!val || !DateUtil.isDateValid(val)) {
      return validationReturnValue(false, i18n.t('validations.validationBDEmpty'));
    }
    const diff = DateUtil.getDiffYearFromNow(val, config.dateFormat.date);

    if (diff < 18) {
      return validationReturnValue(false, i18n.t('validations.validationBD18'));
    }
    if (diff > 90) {
      return validationReturnValue(false, i18n.t('validations.validationBD90'));
    }

    return validationReturnValue(true);
  },

  // eslint-disable-next-line max-len
  // mask for email - /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
  email: (val: string): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationEmailEmpty'));
    }
    if (!validator.validate(val)) {
      return validationReturnValue(false, i18n.t('validations.validationEmailNotValid'));
    }

    return validationReturnValue(true);
  },

  phone: (val: string, code: string, checkPhones: string[] = [], listPhones: string[] = [], msg: string = ''): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationPhoneEmpty'));
    }

    let patt = /^\d+$/g;

    if (!patt.test(val)) {
      return validationReturnValue(false, i18n.t('validations.validationPhoneNotValid'));
    }
    patt = /^[0]/;
    if (patt.test(val)) {
      return validationReturnValue(false, i18n.t('validations.validationPhoneNotZeroStart'));
    }

    if (checkPhones && listPhones.indexOf(val) !== -1) {
      return validationReturnValue(false, msg);
    }

    // eslint-disable-next-line
    switch (code) {
      case countries.switzerland.code:
      case countries.liechtenstein.code:
        patt = /^[7]/;
        if (!patt.test(val)) {
          return validationReturnValue(false, i18n.t('validations.validationPhoneStart7'));
        }
        if (val.length !== 9) {
          return validationReturnValue(false, i18n.t('validations.validationPhoneLength9'));
        }
        break;
      case countries.deutschland.code:
        patt = /^[1]/;
        if (!patt.test(val)) {
          return validationReturnValue(false, i18n.t('validations.validationPhoneStart1'));
        }
        if (val.length < 8) {
          return validationReturnValue(false, i18n.t('validations.validationPhoneLength5'));
        }
        if (val.length > 12) {
          return validationReturnValue(false, i18n.t('validations.validationPhoneLength12'));
        }
        break;
      case countries.austrian.code:
        patt = /^[6]/;
        if (!patt.test(val)) {
          return validationReturnValue(false, i18n.t('validations.validationPhoneStart6'));
        }
        if (val.length !== 8) {
          return validationReturnValue(false, i18n.t('validations.validationPhoneLength8'));
        }
        break;
      case countries.italian.code:
        patt = /^[3]/;
        if (!patt.test(val)) {
          return validationReturnValue(false, i18n.t('validations.validationPhoneStart3'));
        }
        if (val.length !== 10) {
          return validationReturnValue(false, i18n.t('validations.validationPhoneLength10'));
        }
        break;
    }

    return validationReturnValue(true);
  },

  password: (val: string): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationPasswordEmpty'));
    }
    let patt = /[A-Z]/;

    if (!val.match(patt)) {
      return validationReturnValue(false, i18n.t('validations.validationPasswordNotValid'));
    }
    patt = /[a-z]/;
    if (!val.match(patt)) {
      return validationReturnValue(false, i18n.t('validations.validationPasswordNotValid'));
    }
    patt = /[0-9]/;
    const pattSpecial = /[!@#$%^&*]/;

    if (!val.match(patt) && !val.match(pattSpecial)) {
      return validationReturnValue(false, i18n.t('validations.validationPasswordNotValid'));
    }
    if (val.length < 8) {
      return validationReturnValue(false, i18n.t('validations.validationPasswordNotValid'));
    }

    return validationReturnValue(true);
  },

  passwordConfirm: (confirm: string, pass: string): IValidateResult => {
    if (!confirm) {
      return validationReturnValue(false, i18n.t('validations.validationPasswordConfirmEmpty'));
    }
    if (confirm !== pass) {
      return validationReturnValue(false, i18n.t('validations.validationPasswordConfirmNotValid'));
    }

    return validationReturnValue(true);
  },

  freebeeCode: (val: string): IValidateResult => {
    if (val.length !== 10) {
      return validationReturnValue(false, i18n.t('validations.validationFreebeeCodeLength10'));
    }

    return validationReturnValue(true);
  },

  iban: (val: string): IValidateResult => {
    const patt = /^[0-9]{2}/;

    if (val.length > 34 || val.length < 15 || patt.test(val)) {
      return validationReturnValue(false, i18n.t('validations.validationIBANNotValid'));
    }

    return validationReturnValue(true);
  },

  firstName: (val: string): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationFirstNameEmpty'));
    }

    if (val.length < 2) {
      return validationReturnValue(false, i18n.t('validations.validationFirstNameLength'));
    }

    const patt = /[0-9]/;

    if (patt.test(val)) {
      return validationReturnValue(false, i18n.t('validations.validationFirstNameNotNumbers'));
    }

    return validationReturnValue(true);
  },

  lastName: (val: string): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationLastNameEmpty'));
    }

    if (val.length < 2) {
      return validationReturnValue(false, i18n.t('validations.validationLastNameLength'));
    }

    const patt = /[0-9]/;

    if (patt.test(val)) {
      return validationReturnValue(false, i18n.t('validations.validationLastNameNotNumbers'));
    }

    return validationReturnValue(true);
  },

  postcode: (val: string, code: string = countries.deutschland.code): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationPostcodeEmpty'));
    }

    let numberDigits = null;
    let msg = '';
    // eslint-disable-next-line

    switch (code) {
      case countries.switzerland.code:
      case countries.austrian.code:
        numberDigits = 4;
        msg = i18n.t('validations.validationPostcodeLength4');
        break;
      case countries.deutschland.code:
        numberDigits = 5;
        msg = i18n.t('validations.validationPostcodeLength5');
        break;
      default:
        numberDigits = 5;
        msg = i18n.t('validations.validationPostcodeLength5');
        break;
    }
    if (numberDigits && msg && val.length !== numberDigits) {
      return validationReturnValue(false, msg);
    }

    return validationReturnValue(true);
  },

  price: (val: string, errorMsgEmpty?: string, errorMsgValidation?: string): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, errorMsgEmpty || i18n.t('validations.validationPriceEmpty'));
    }
    const patt = /^\d+\.{0,1}\d{0,2}?$/g;

    if (!patt.test(Utils.priceProcess(val))) {
      return validationReturnValue(false, errorMsgValidation || i18n.t('validations.validationPriceNotValid'));
    }

    return validationReturnValue(true);
  },

  hasTwoDecimals: (val: string | number, errorMsgEmpty?: string, errorMsgValidation?: string) => {
    if (!val || val === 0) {
      return validationReturnValue(false, errorMsgEmpty || i18n.t('validation.fieldRequired'));
    }

    const pattern = /^\d+(\.\d{1,2})?$/;

    if (!pattern.test(val as string)) {
      return validationReturnValue(false, errorMsgValidation || i18n.t('validations.validationWithTwoDecimals'));
    }

    return validationReturnValue(true);
  },

  yearlySpending: (val: string) =>
    Validation.price(val, i18n.t('validations.validationYearlySpendingEmpty'), i18n.t('validations.validationYearlySpendingNotValid')),

  yearlySpendingCost: (val: string) =>
    Validation.price(
      val,
      i18n.t('validations.validationYearlySpendingCostEmpty'),
      i18n.t('validations.validationYearlySpendingCostNotValid'),
    ),

  document: (filetype: string, filesize: number): IValidateResult => {
    if (filesize > 20971520) {
      return validationReturnValue(false, i18n.t('validations.validationFilesizeIsBig'));
    }

    const validationTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];

    if (validationTypes.indexOf(filetype.toLowerCase()) === -1) {
      return validationReturnValue(false, i18n.t('validations.validationFiletypeIsWrong'));
    }

    return validationReturnValue(true);
  },

  // eslint-disable-next-line
  startDate: (val: string): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationStartDateEmpty'));
    }

    return validationReturnValue(true);
  },

  endDate: (val: string, startDate: string): IValidateResult => {
    if (val) {
      const diff = DateUtil.getDiffDaysFromDate(val, startDate, config.dateFormat.date);

      if (diff > 0) {
        return validationReturnValue(false, i18n.t('validations.validationEndDate'));
      }
    }

    return validationReturnValue(true);
  },

  address: (val: string): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationAddressEmpty'));
    }

    const patt = /[0-9]/;

    if (!patt.test(val)) {
      return validationReturnValue(false, i18n.t('validations.validationAddressContainNumbers'));
    }

    return validationReturnValue(true);
  },

  addressWithoutNumber: (val: string): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationAddressWithoutNumberEmpty'));
    }

    return validationReturnValue(true);
  },

  houseNumber: (val: string): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationHouseNumberEmpty'));
    }

    const patt = /[0-9]/;

    if (!patt.test(val)) {
      return validationReturnValue(false, i18n.t('validations.validationHouseNumberContainNumbers'));
    }

    return validationReturnValue(true);
  },

  city: (val: string): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationCityEmpty'));
    }

    const patt = /[0-9]/;

    if (patt.test(val)) {
      return validationReturnValue(false, i18n.t('validations.validationCityNotNumbers'));
    }

    return validationReturnValue(true);
  },

  paypal: (val: string): IValidateResult => {
    if (!val) {
      return validationReturnValue(false, i18n.t('validations.validationPaypalEmailEmpty'));
    }
    if (!validator.validate(val)) {
      return validationReturnValue(false, i18n.t('validations.validationPaypalEmailNotValid'));
    }

    return validationReturnValue(true);
  },

  emptyValue: (val: string, msg: string) => validationReturnValue(!!val, msg || ''),

  validated: (obj: IValidateResult): boolean => obj.validated,
};

export default Validation;
